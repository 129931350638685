import { CommandBar, ICommandBarItemProps, Link } from '@fluentui/react'
import { createColumn, createCustomRenderedColumn } from '../../../../lib/gridHelper'
import { getPropertyName } from '../../../../lib/interfaceUtils'
import React, { useMemo, useState } from 'react'
import commandBarStyles from '../../../../styles/commandBarStyles'
import { useNavigate } from 'react-router-dom'
import { GebouwGebouwdelenListDto, zodiosHooks } from '../../../../api/ApiClient'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { getTitleAndMessage } from '../../../../services/HandleError'
import { availableUrls } from '../../../../routing/AvailableUrls'
import { z } from 'zod'
import { useBoolean } from '@fluentui/react-hooks'
import { VgtPagedTable } from '../../../../components/VgtPagedTable/VgtPagedTable'
import { usePagedParams } from '../../../../services/usePagedParams'
import { GebouwDeelFormPanel } from './gebouwDeelFormPanel'

type GebouwGebouwdelenListDtoType = z.infer<typeof GebouwGebouwdelenListDto>;

interface IGebouwDeelProps {
  gebouwId: number
}

const GebouwDelen: React.FC<IGebouwDeelProps> = props => {
  const navigate = useNavigate()
  const [error, setError] = useState<string>()
  const [inEditMode, { setTrue: openEditPanel, setFalse: dismissEditPanel }] = useBoolean(false)
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const { validatedSearchParams, setOrder, setPage } = usePagedParams()

  const { data, invalidate, isLoading } = zodiosHooks.useGetGebouwenGebouwIdgebouwdelen({
    params: { gebouwId: props.gebouwId as number },
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  }, { enabled: props.gebouwId !== 0 })

  const handleItemInvoked = (id: string) => {
    navigate(availableUrls.Gebouwdeel.replace(':id', id))
  }

  const AddToSelection = () => {
    openEditPanel()
  }

  const { mutate: deleteGebouwdeel } = zodiosHooks.useDeleteGebouwdelen({}, {
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const RemoveFromSelection = () => {
    deleteGebouwdeel({ itemsToDelete: selectedIds.map(v => v) })
  }

  const selectCommandBarItems: ICommandBarItemProps[] = useMemo(
    () => [
      {
        key: 'add',
        iconOnly: true,
        iconProps: { iconName: 'Add', className: 'icon' },
        split: false,
        ariaLabel: 'Toevoegen',
        onClick: AddToSelection
      },
      {
        key: 'delete',
        iconOnly: true,
        iconProps: { iconName: 'Delete', className: 'icon' },
        split: false,
        ariaLabel: 'Verwijderen',
        disabled: false,
        onClick: RemoveFromSelection
      }
    ],
    [selectedIds.length]
  )
  const columns = [
    createCustomRenderedColumn(
      'Code',
      getPropertyName<GebouwGebouwdelenListDtoType>('code'),
      (item: GebouwGebouwdelenListDtoType) => (
        <Link key={item.gebouwdeelId} onClick={() => handleItemInvoked(item.gebouwdeelId.toString())}>
          {item.code}
        </Link>
      ),
      'XL',
      false
    ),
    createColumn('Naam', getPropertyName<GebouwGebouwdelenListDtoType>('naam'), 'MAX'),
    createColumn('Gebouw Naam', getPropertyName<GebouwGebouwdelenListDtoType>('gebouwNaam'), 'MAX')
  ]


  return (
    <>
      <GebouwDeelFormPanel gebouwDeelId={0} gebouwId={props.gebouwId} isOpen={inEditMode}
                           dismissPanel={dismissEditPanel}
                           invalidate={invalidate} />
      <CommandBar items={selectCommandBarItems} styles={commandBarStyles} />
      <ErrorMessageBar error={error} />
      <div style={{ height: '70vh', position: 'relative' }}>
        <VgtPagedTable
          isLoading={isLoading}
          items={data?.items ?? []}
          columns={columns}
          height={'203'}
          pagingInfo={{
            pageSize: validatedSearchParams.pageSize,
            currentPage: validatedSearchParams.page,
            totalCount: data?.totalCount ?? 0,
            totalPages: data?.totalPages ?? 0,
            hasNextPage: data?.hasNextPage ?? false,
            hasPreviousPage: data?.hasPreviousPage ?? false,
            sortKey: validatedSearchParams.sortKey || 'gebouwdeelId',
            sortDirection: validatedSearchParams.sortDirection || 'desc'
          }}
          onSelectionChanged={setSelectedIds}
          getKey={(item) => item?.gebouwdeelId}
          onItemInvoked={(item) => handleItemInvoked(item.gebouwdeelId.toString())}
          onPageChanged={setPage}
          onSortChanged={setOrder}
        />
      </div>
    </>
  )
}

export default GebouwDelen
