import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { GebouwdeelListDto, zodiosHooks } from '../../../api/ApiClient'
import {
  IColumn,
  PanelType,
  SearchBox,
  SelectionMode,
  Selection, IObjectWithKey, ShimmeredDetailsList
} from '@fluentui/react'
import { debounce } from 'lodash'
import React from 'react'
import { getPropertyName } from 'lib/interfaceUtils'
import { getTitleAndMessage } from '../../../services/HandleError'
import { IGebouwDeel } from '../fysiek/gebouwen/gebouwenSlice'
import { z } from 'zod'
import FluentPanel from '../../../components/FluentPanel/FluentPanel'
import { OkCancelButtonStack } from '../../../components/OkCancelButtonStack/OkCancelButtonStack'
import { FieldErrorsToMessage } from '../../../components/ErrorMessageBar/ErrorMessageBar'

interface IEditPanelProps {
  isOpen: boolean
  dismissPanel: any
  onSelect: (gebouwdelen: IGebouwDeel[]) => void
  excludeIds: number[]
}

type GebouwdeelListDtoType = z.infer<typeof GebouwdeelListDto>;

const createFilteredGebouwdelen = (gebouwdelen: GebouwdeelListDtoType[], excludeIds: number[]) => {
  return gebouwdelen.filter(gebouwdeel => excludeIds.findIndex(g => g === gebouwdeel.id) === -1)
}

const SelectGebouwdelenPanel: React.FC<IEditPanelProps> = props => {
  const [filter, setFilter] = useState<string>('')
  const [error, setError] = useState<string>()
  const [currentSearchValue, setCurrentSearchValue] = useState<string>()

  const { data, isLoading } = zodiosHooks.useGetGebouwdelen({
    queries: {
      SortKey: 'naam',
      SortDirection: 'asc',
      PageIndex: 1,
      PageSize: 50,
      Filter: filter
    }
  }, {
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const gebouwdelen = useMemo(() => createFilteredGebouwdelen(data?.items ?? [], props.excludeIds), [data, props.excludeIds])

  const {
    handleSubmit
  } = useForm<IGebouwDeel>({ mode: 'onChange' })

  const clearSearch = () => {
    setCurrentSearchValue('')
  }

  const closePanel = () => {
    setError('')
    clearSearch()
    props.dismissPanel()
  }

  const onCancel = () => {
    closePanel()
  }

  useEffect(() => {
    if (props.isOpen) clearSearch()
  }, [props.isOpen])

  const onSubmit = () => {
    if (selection.count > 0) {
      const items = selection.getSelection() as IGebouwDeel[]
      props.onSelect(items)
    }
  }

  const [selection] = React.useState(() => {
    const s = new Selection<IGebouwDeel>({
      selectionMode: SelectionMode.multiple,
      onSelectionChanged: () => selection.count,
      getKey: item => item.id
    })
    return s
  })

  const delayedSearch = useCallback(
    debounce((value: string) => setFilter(value), 500),
    []
  )

  const onSearchBoxChanged = (event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
    setCurrentSearchValue(newValue ?? '')
      delayedSearch(newValue ?? '')
  }

  useEffect(() => {
    return () => {
      delayedSearch.cancel()
    }
  }, [])


  const onClearSearchBox = () => {
    clearSearch()
  }

  const columns = [
    {
      name: 'Naam',
      fieldName: getPropertyName<IGebouwDeel>('naam'),
      key: getPropertyName<IGebouwDeel>('naam'),
      minWidth: 50,
      maxWidth: 400,
      isResizable: true,
      onRender: (item: IGebouwDeel) => <span>{item.code} {item?.naam.length === 0 ? '' : ' - ' + item.naam}</span>
    }
  ] as IColumn[]

  return (
    <FluentPanel type={PanelType.medium} isOpen={props.isOpen} onDismiss={onCancel} headerText={'Koppelen gebouwdelen'}
                 onDismissed={onCancel} error={error}>

      <SearchBox placeholder="Zoeken" onChange={onSearchBoxChanged} onClear={onClearSearchBox}
                 value={currentSearchValue} autoComplete="off" />

      <ShimmeredDetailsList
        enableShimmer={isLoading}
        items={gebouwdelen.length === 0 ? [{
          key: 'no-data',
          code: 'Geen resultaten gevonden',
          naam: ''
        }] : gebouwdelen}
        isPlaceholderData={gebouwdelen.length === 0}
        selection={selection as Selection<IObjectWithKey>}
        columns={columns} />

      <FluentPanel.Footer>
        <OkCancelButtonStack defaultButtonLabel={'Koppelen'} isSubmitting={false} isLoading={isLoading}
                             onOkClick={handleSubmit(onSubmit, (errors) => {
                               setError(FieldErrorsToMessage(errors))
                             })}
                             onCancelClick={onCancel} />
      </FluentPanel.Footer>
    </FluentPanel>
  )
}

export default SelectGebouwdelenPanel
