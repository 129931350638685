import { z } from 'zod'
import {
  FieldServiceConfigurationSecretUpdateDto,
  zodiosHooks
} from '../../api/ApiClient'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { getTitleAndMessage } from '../../services/HandleError'
import FluentPanel from '../../components/FluentPanel/FluentPanel'
import { PanelType } from '@fluentui/react/lib/Panel'
import VgtTextField from '../../components/VgtTextField'
import { getPropertyName } from '../../lib/interfaceUtils'
import VgtHorizontalDatePicker from '../../components/VgtDatePicker/VgtHorizontalDatePicker'
import { OkCancelButtonStack } from '../../components/OkCancelButtonStack/OkCancelButtonStack'
import { FieldErrorsToMessage } from '../../components/ErrorMessageBar/ErrorMessageBar'

export interface IEditPanelProps {
  isOpen: boolean
  dismissPanel: any
  invalidate: () => void
}

export type CreateUpdateType = z.infer<typeof FieldServiceConfigurationSecretUpdateDto>;

const defaultEntity = {
  clientSecret: '',
  clientSecretExpiresOn: ''
} as CreateUpdateType

export const FieldServiceSecretPanel: React.FC<IEditPanelProps> = props => {

  const [error, setError] = React.useState<string>()

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<CreateUpdateType>({
    resolver: zodResolver(FieldServiceConfigurationSecretUpdateDto), mode: 'all', defaultValues: defaultEntity
  })

  const { mutate, isLoading: isModifying } = zodiosHooks.usePutSysteemInstellingenFieldServiceSecret({}, {
      onSuccess: () => onCancel(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  useEffect(() => {
    if (props.isOpen) {
      reset(defaultEntity)
    }
  }, [props.isOpen])

  const onCancel = () => {
    props.invalidate()
    reset({})
    setError(undefined)
    props.dismissPanel()
  }

  const onSubmit = (data: CreateUpdateType) => {
    setError(undefined)
    mutate(data)
  }

  return (
    <FluentPanel type={PanelType.medium} isOpen={props.isOpen} onDismiss={onCancel} headerText={'Wijzigen Field Service Client Secret'} onDismissed={onCancel} error={error}>
      <VgtTextField label="Client Secret" name={getPropertyName<CreateUpdateType>('clientSecret')} control={control} errors={errors} required />
      <VgtHorizontalDatePicker label="Vervalt op" name={getPropertyName<CreateUpdateType>('clientSecretExpiresOn')} control={control} errors={errors} required />

      <FluentPanel.Footer>
        <OkCancelButtonStack isSubmitting={isModifying} isLoading={false} onOkClick={handleSubmit(onSubmit, (errors) => setError(FieldErrorsToMessage(errors)))} onCancelClick={onCancel} />
      </FluentPanel.Footer>
    </FluentPanel>
  )


}
