import { z } from 'zod'
import { DocumentConfigurationUpdateDto, zodiosHooks } from '../../api/ApiClient'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { getTitleAndMessage } from '../../services/HandleError'
import FluentPanel from '../../components/FluentPanel/FluentPanel'
import { PanelType } from '@fluentui/react/lib/Panel'
import VgtTextField from '../../components/VgtTextField'
import { getPropertyName } from '../../lib/interfaceUtils'
import { OkCancelButtonStack } from '../../components/OkCancelButtonStack/OkCancelButtonStack'
import { FieldErrorsToMessage } from '../../components/ErrorMessageBar/ErrorMessageBar'

export interface IEditPanelProps {
  isOpen: boolean
  dismissPanel: any
  invalidate: () => void
}

export type CreateUpdateType = z.infer<typeof DocumentConfigurationUpdateDto>;

const defaultEntity = {
  accountName: ''
} as CreateUpdateType

export const DocumentConfigurationPanel: React.FC<IEditPanelProps> = props => {

  const [error, setError] = React.useState<string>()

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<CreateUpdateType>({
    resolver: zodResolver(DocumentConfigurationUpdateDto), mode: 'all', defaultValues: defaultEntity
  })


  const isActive = true

  const { mutate, isLoading: isModifying } = zodiosHooks.usePutSysteemInstellingenDocumentConfiguration({}, {
      onSuccess: () => onCancel(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const { isLoading, invalidate } = zodiosHooks.useGetSysteemInstellingenDocumentConfiguration({}, {
    enabled: props.isOpen,
    onSuccess: (data) => reset(data)
  })


  useEffect(() => {
    if (props.isOpen) {
      invalidate()
    }
  }, [props.isOpen])

  const onCancel = () => {
    props.invalidate()
    reset({})
    setError(undefined)
    props.dismissPanel()
  }

  const onSubmit = (data: CreateUpdateType) => {
    setError(undefined)

    mutate(data)
  }


  return (
    <FluentPanel type={PanelType.medium} isOpen={props.isOpen} onDismiss={onCancel} headerText={'Wijzigen Document configuratie'} onDismissed={onCancel} error={error}>
      {/* <VgtCheckbox name={getPropertyName<CreateUpdateType>('isActief')} label="Actief" control={control} errors={errors} /> */}
      <p>How to create storage account?</p>
      <VgtTextField label="Account Name" name={getPropertyName<CreateUpdateType>('accountName')} control={control} errors={errors} required disabled={!isActive} />

      <FluentPanel.Footer>
        <OkCancelButtonStack isSubmitting={isModifying} isLoading={isLoading} onOkClick={handleSubmit(onSubmit, (errors) => setError(FieldErrorsToMessage(errors)))} onCancelClick={onCancel} />
      </FluentPanel.Footer>
    </FluentPanel>
  )
}


