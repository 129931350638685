import { Pivot, PivotItem, Spinner, SpinnerSize, Stack } from '@fluentui/react'
import React, { FC, useEffect, useRef, useState } from 'react'
import { IconList, IconProps } from './IconList'
import { useSelector } from 'react-redux'
import { categorieAdded, categorieSelected, deleteClicked, detailSelected, elementAdded, elementSelected, getAllInstallaties, getCurrentStep, getError, getIconsToPresent, getLoadingState, getSelectedCategorie, getSelectedDetail, getSelectedElement, getSelectedInstallatie, goBack, initialize, installatieSelected, newFromInstallatie, saveInstallatie, WizardStep } from './CartotheekStateMachine'
import { useAppDispatch } from '../../../store'
import { SelectedIcon } from './SelectedIcon'
import { CartotheekDetail } from './CartotheekDetail'
import { IInstallatie } from '../installatie/installatieTypes'
import { CartotheekList } from './CartotheekList'
import { CartotheekKeuken, KeukenDataSoort } from './keuken/CartotheekKeuken'
import ErrorMessageBar from '../../../components/ErrorMessageBar/ErrorMessageBar'

interface CartotheekPanelProps {
  vgeId?: number | undefined
  gebouwId?: number | undefined
  gebouwdeelId?: number | undefined
}

export const CartotheekWizardPanel: FC<CartotheekPanelProps> = ({ vgeId, gebouwId, gebouwdeelId }) => {
  const data = useSelector(getIconsToPresent)
  const step = useSelector(getCurrentStep)
  const loadingState = useSelector(getLoadingState)
  const error = useSelector(getError)
  const selectedCategory = useSelector(getSelectedCategorie)
  const selectedElement = useSelector(getSelectedElement)
  const selectedDetail = useSelector(getSelectedDetail)
  const selectedInstallatie = useSelector(getSelectedInstallatie)
  const dispatch = useAppDispatch()
  const iconListRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(400)
  const relatedEntityType = vgeId !== undefined ? 'vge' : gebouwId !== undefined ? 'gebouw' : gebouwdeelId !== undefined ? 'gebouwdeel' : 'undefined'
  const relatedEntityId = vgeId ?? gebouwId ?? gebouwdeelId ?? -1

  useEffect(() => {
    if (vgeId === undefined && gebouwId === undefined && gebouwdeelId === undefined) return

    dispatch(initialize({ relatedEntityType: relatedEntityType, relatedEntityId: relatedEntityId }))
  }, [vgeId])

  useEffect(() => {
    const handleResize = () => {
      const headersHeight = 10 + 20 + 10 + 20
      const availableHeight = window.innerHeight - headersHeight
      if (iconListRef.current) {
        setHeight(availableHeight - iconListRef.current.offsetTop - 20)
      } else {
        setHeight(availableHeight - 300)
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (loadingState === 'failed')
    return <div style={{ marginTop: 20, width: 100 }}>
      <ErrorMessageBar error={error!} />
    </div>

  if ((loadingState === 'pending' && step === WizardStep.initial) || (vgeId === undefined && gebouwId === undefined && gebouwdeelId === undefined))
    return <div style={{ marginTop: 20, width: 100 }}>
      <Spinner size={SpinnerSize.large} />
    </div>

  const onSelectIcon = (icon: IconProps) => {
    if (step === WizardStep.selectCategorie) {
      dispatch(categorieSelected({ relatedEntityType: relatedEntityType, relatedEntityId: relatedEntityId, categorie: icon }))
    }
    if (step === WizardStep.selectElement) {
      dispatch(elementSelected({ relatedEntityType: relatedEntityType, relatedEntityId: relatedEntityId, element: icon }))
    }
    if (step === WizardStep.selectElementDetail) {
      dispatch(detailSelected({ relatedEntityType: relatedEntityType, relatedEntityId: relatedEntityId, elementdetail: icon }))
    }
  }

  const onNewClicked = () => {
    dispatch(newFromInstallatie())
  }

  const onDeleteClicked = (id: number) => {
    dispatch(deleteClicked({ installatieId: id }))
  }

  const onGoBack = () => {
    dispatch(goBack())
  }

  const onInstallatieSelected = (selectedInstallatie: IInstallatie) => {
    dispatch(installatieSelected(selectedInstallatie))
  }

  const onSubmit = async (data: IInstallatie) => {
    await dispatch(saveInstallatie({ installatie: data }))
  }

  const onPivotClicked = (item: PivotItem | undefined) => {
    if (item === undefined) return

    dispatch(getAllInstallaties({ relatedEntityType: relatedEntityType, relatedEntityId: relatedEntityId }))

    if (item.props.itemKey === 'categories') {
      dispatch(initialize({ relatedEntityType: relatedEntityType, relatedEntityId: relatedEntityId }))
    }
  }

  return (
    <Pivot onLinkClick={i => onPivotClicked(i)}>
      <PivotItem headerText={`Per categorie`} itemKey="categories" style={{ margin: 20 }}>
        {step <= WizardStep.listInstallaties &&
          <>
            <Stack horizontal tokens={{ childrenGap: 20 }}>
              {(step > WizardStep.selectCategorie && step !== WizardStep.listInstallaties) &&
                <SelectedIcon level={'categorie'} selectedIcon={selectedCategory}
                              onSelect={() => dispatch(initialize({ relatedEntityType: relatedEntityType, relatedEntityId: relatedEntityId }))}
                              onAdd={(categorieIcon) => {
                                dispatch(categorieAdded({
                                  relatedEntityType: relatedEntityType,
                                  relatedEntityId: relatedEntityId,
                                  categorie: categorieIcon
                                }))
                              }}
                />}
              {(step > WizardStep.selectElement && step !== WizardStep.listInstallaties) &&
                <SelectedIcon level={'element'} selectedIcon={selectedElement}
                              onSelect={() => dispatch(categorieSelected({ relatedEntityType: relatedEntityType, relatedEntityId: relatedEntityId, categorie: selectedCategory }))}
                              onAdd={(elementIcon) => {
                                dispatch(elementAdded({
                                  relatedEntityType: relatedEntityType,
                                  relatedEntityId: relatedEntityId,
                                  element: elementIcon
                                }))
                              }}
                />}

              {(step > WizardStep.selectElementDetail && step !== WizardStep.listInstallaties) && <SelectedIcon level={'detail'} selectedIcon={selectedDetail} onSelect={() => dispatch(elementSelected({ relatedEntityType: relatedEntityType, relatedEntityId: relatedEntityId, element: selectedElement }))}
              />}
            </Stack>
            {step <= WizardStep.selectElementDetail && <>
              <div style={{ marginBottom: 10 }}><b>
                {step === WizardStep.selectCategorie && 'Categorieën'}
                {step === WizardStep.selectElement && 'Elementen'}
                {step === WizardStep.selectElementDetail && 'Elementdetails | Materiaalsoorten'}
              </b></div>
              <div ref={iconListRef} style={{ height: `${height}px`, overflowY: 'auto', overflowX: 'hidden' }}>
                <IconList icons={data} onSelected={(icon) => onSelectIcon(icon)} />
              </div>
            </>}
          </>}
        {step === WizardStep.addInstallatie && selectedInstallatie &&
          <div style={{ height: `${height + 40}px`, overflow: 'auto' }}>
            <CartotheekDetail onSubmit={onSubmit} onCancel={() => onGoBack()} onNewClicked={onNewClicked} />
          </div>
        }
        {step === WizardStep.editInstallatie && selectedInstallatie &&
          <div style={{ height: `${height + 40}px`, overflow: 'auto' }}>
            <CartotheekDetail onSubmit={onSubmit} onCancel={() => onGoBack()} onNewClicked={onNewClicked} onDeleteClicked={onDeleteClicked} />
          </div>
        }
        {step === WizardStep.listInstallaties &&
          <div style={{ height: `${height - 100}px`, overflow: 'auto' }}>
            <CartotheekList onAdd={onNewClicked} onSelected={onInstallatieSelected} onClose={onGoBack} />
          </div>
        }
      </PivotItem>

      {vgeId !== undefined && gebouwId === undefined &&
      <PivotItem headerText="Keukenvoorzieningen" itemKey="keuken">
        <CartotheekKeuken vgeId={vgeId ?? -1} keukenDataSoort={KeukenDataSoort.Keuken}></CartotheekKeuken>
      </PivotItem>
      }
      {vgeId !== undefined && gebouwId === undefined &&
        <PivotItem headerText="Sanitairvoorzieningen" itemKey="sanitair">
        <CartotheekKeuken vgeId={vgeId ?? -1} keukenDataSoort={KeukenDataSoort.Sanitair}></CartotheekKeuken>
      </PivotItem>
      }
      <PivotItem headerText="Totaaloverzicht" itemKey="total">
        <div style={{ height: `${height + 70}px`, overflow: 'auto' }}>
          {step === WizardStep.editInstallatie && selectedInstallatie &&
            <div style={{ height: `${height + 40}px`, overflow: 'auto' }}>
              <CartotheekDetail onSubmit={onSubmit} onCancel={() => onGoBack()} onNewClicked={onNewClicked} onDeleteClicked={onDeleteClicked} />
            </div>
          }
          {step !== WizardStep.editInstallatie &&
            <CartotheekList onSelected={onInstallatieSelected} />
          }
        </div>
      </PivotItem>
    </Pivot>
  )
}
