import React, { useState } from 'react'
import { IDropdownOption, PanelType, Pivot, PivotItem, Stack, StackItem } from '@fluentui/react'
import { getPropertyName } from 'lib/interfaceUtils'
import { z } from 'zod'
import { TenantTaakAddOrUpdateDto, zodiosHooks } from 'api/ApiClient'
import useRoles from 'services/UseRoles'
import { getTitleAndMessage } from 'services/HandleError'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { OkCancelButtonStack } from 'components/OkCancelButtonStack/OkCancelButtonStack'
import VgtCheckbox from '../../../../components/FluentCheckbox'
import FluentPanel from '../../../../components/FluentPanel/FluentPanel'
import { FieldErrorsToMessage } from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { InnerColumn } from './InnerColumn'

export interface IEditPanelProps {
  taakId: number,
  tenantTaakId: number,
  isOpen: boolean
  dismissPanel: () => void
  invalidate: () => void
}

const TenantTaakAddOrUpdateFormSchema = TenantTaakAddOrUpdateDto.omit({ taakNaam: true })
export type CreateUpdateType = z.infer<typeof TenantTaakAddOrUpdateFormSchema>;

export enum TaakType {
  Onbekend = 0,
  Onderhoudscontract = 2,
  GeenService = 1
}

export enum Betaler {
  Corporatie = 1,
  Huurder = 2,
  Dienstencomponent = 3
}

export const defaultTenantTaak : CreateUpdateType = {
  tenantTaakId: 0,
  taakId: 0,
  // taakNaam: '',
  isGecombineerd: true,
  dienstencomponentIds: [],

  meetkundigeEenheidId: 0,
  onderhoudstaakErpId: 0,
  onderhoudsspecialismeId: 0,
  bewerkingstijdMin: 0,
  urgentieDgn: 0,
  afspraak: false,
  actief: false,
  tonenAanKlant: false,
  verkoopprijs: 0,
  kostprijs: 0,
  betalerOptieId: 0,
  inspectieVereist: false,
  teBeoordelen: false,
  taakTypeOptieId: TaakType.Onbekend,
  portalToelichtingBijOntbrekendContract: '',
  portalToelichtingZelfOplossen: '',
  isSpoed: false,
  spoedToelichting: '',

  dienstencomponentIdsBuiten: [],
  meetkundigeEenheidIdBuiten: 0,
  onderhoudstaakErpIdBuiten: 0,
  onderhoudsspecialismeIdBuiten: 0,
  bewerkingstijdMinBuiten: 0,
  urgentieDgnBuiten: 0,
  afspraakBuiten: false,
  tonenAanKlantBuiten: false,
  verkoopprijsBuiten: 0,
  kostprijsBuiten: 0,
  betalerOptieIdBuiten: 0,
  inspectieVereistBuiten: false,
  teBeoordelenBuiten: false,
  taakTypeOptieIdBuiten: TaakType.Onbekend,
  portalToelichtingBijOntbrekendContractBuiten: '',
  portalToelichtingZelfOplossenBuiten: '',
  isSpoedBuiten: false,
  spoedToelichtingBuiten: '',

  actiefZakelijk: false,
  isGecombineerdZakelijk: true,

  dienstencomponentIdsZakelijk: [],
  meetkundigeEenheidIdZakelijk: 0,
  onderhoudstaakErpIdZakelijk: 0,
  onderhoudsspecialismeIdZakelijk: 0,
  bewerkingstijdMinZakelijk: 0,
  urgentieDgnZakelijk: 0,
  afspraakZakelijk: false,
  tonenAanKlantZakelijk: false,
  verkoopprijsZakelijk: 0,
  kostprijsZakelijk: 0,
  betalerOptieIdZakelijk: 0,
  inspectieVereistZakelijk: false,
  teBeoordelenZakelijk: false,
  taakTypeOptieIdZakelijk: TaakType.Onbekend,
  portalToelichtingBijOntbrekendContractZakelijk: '',
  portalToelichtingZelfOplossenZakelijk: '',
  isSpoedZakelijk: false,
  spoedToelichtingZakelijk: '',

  meetkundigeEenheidIdZakelijkBuiten: 0,
  dienstencomponentIdsZakelijkBuiten: [],
  onderhoudstaakErpIdZakelijkBuiten: 0,
  onderhoudsspecialismeIdZakelijkBuiten: 0,
  bewerkingstijdMinZakelijkBuiten: 0,
  urgentieDgnZakelijkBuiten: 0,
  afspraakZakelijkBuiten: false,
  tonenAanKlantZakelijkBuiten: false,
  verkoopprijsZakelijkBuiten: 0,
  kostprijsZakelijkBuiten: 0,
  betalerOptieIdZakelijkBuiten: 0,
  inspectieVereistZakelijkBuiten: false,
  teBeoordelenZakelijkBuiten: false,
  taakTypeOptieIdZakelijkBuiten: TaakType.Onbekend,
  portalToelichtingBijOntbrekendContractZakelijkBuiten: '',
  portalToelichtingZelfOplossenZakelijkBuiten: '',
  isSpoedZakelijkBuiten: false,
  spoedToelichtingZakelijkBuiten: ''

}

export const taakTypeOptions : IDropdownOption[] = [
  { key: TaakType.Onbekend, text: '---' },
  { key: TaakType.Onderhoudscontract, text: 'Onderhoudscontract' },
  { key: TaakType.GeenService, text: 'Geen service' }
]

export const TenantTaakFormPanel: React.FC<IEditPanelProps> = props => {
  const { isReparatieboom } = useRoles()
  const [error, setError] = React.useState<string>()
  const [title, setTitle] = useState('Inrichten taken')
  const [inspectieSpecialismeId, setInspectieSpecialismeId] = useState<number>(0)

  const {
    control,
    reset,
    watch,
    setValue,
    getValues,
    formState: { isSubmitting, errors }
  } = useForm<CreateUpdateType>({
    resolver: zodResolver(TenantTaakAddOrUpdateFormSchema), mode: 'all', defaultValues: defaultTenantTaak
  })

  zodiosHooks.useGetTenantTaakId({ params: { id: props.tenantTaakId ?? 0 } }, {
      onSuccess: (data) => {
        reset(data)
        setTitle(`Inrichten taken - ${data.taakNaam}`)
      },
      enabled: props.tenantTaakId !== 0
    }
  )

  const { mutate: updateTenantTaak } = zodiosHooks.usePutTenantTaakId(
    { params: { id: props.tenantTaakId } }, {
      onSuccess: () => onPanelDismiss(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const { mutate: addTenantTaak } = zodiosHooks.usePostTenantTaak(
    {},
    {
      onSuccess: () => onPanelDismiss(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const { data: tenantData } = zodiosHooks.useGetApiTenant()

  const { data: dienstencomponenten } = zodiosHooks.useGetDienstenComponentasOpties()
  const { data: meetkundigeEenheden } = zodiosHooks.useGetApiMeetkundigeEenhedenasOptions()
  const { data: onderhoudstaken } = zodiosHooks.useGetOnderhoudsTaakErpopties()
  const { data: onderhoudsspecialisme } = zodiosHooks.useGetOnderhoudsspecialismeOpties(
    {}, {
      onSuccess: (data) => {
        setInspectieSpecialismeId(data.find(s => s.text.startsWith('INS -'))?.key ?? 0)
      }
    }
  )
  const { data: betalers } = zodiosHooks.useGetTenantKostensoortBetaleropties()

  const onPanelDismiss = () => {
    props.invalidate()
    reset(defaultTenantTaak)
    setTitle('Inrichten taken')
    setError(undefined)
    props.dismissPanel()
  }

  const onSubmitClick = () => {
    const data = { ...defaultTenantTaak, ...getValues(), tenantTaakId: props.tenantTaakId, taakId: props.taakId, taakNaam: '' }

    const parseResult = TenantTaakAddOrUpdateFormSchema.safeParse(data)
    if (!parseResult.success) {

      // create a dictionary with the path as key and the error message as value
      let errors = Object.fromEntries(parseResult.error.issues.map(i => [i.path[0], i.message]))
      // filter out all fields ending with Zakelijk when tenant has no zakelijk verhuur
      if (tenantData?.heeftZakelijkeVerhuur === false) {
        errors = Object.fromEntries(Object.entries(errors).filter(([key]) => !key.endsWith('Zakelijk')))
        errors = Object.fromEntries(Object.entries(errors).filter(([key]) => !key.endsWith('ZakelijkBuiten')))
      }

      // if there are still errors, show them
      if (Object.keys(errors).length > 0) {
        setError(FieldErrorsToMessage(errors))
        return
      }
    }

    if (props.tenantTaakId !== 0) {
      updateTenantTaak(data)
    } else {
      addTenantTaak(data)
    }
  }

  const onIsGecombineerdChanged = (checked: boolean | undefined) => {
    if (checked === undefined) return

    if (!checked) {
      const data = getValues()
      // copy all fields that contain Buiten in the name to the Buiten fields from the original binnen data
      for (const key in data) {
        if (key.endsWith('Buiten')) {
          setValue(key, data[key.replace('Buiten', '')])
        }
      }
    }
  }

  const onIsGecombineerdZakelijkChanged = (checked: boolean | undefined) => {
    if (checked === undefined) return

    if (!checked) {
      const data = getValues()
      // copy all fields that contain Buiten in the name to the Buiten fields from the original binnen data
      for (const key in data) {
        if (key.endsWith('ZakelijkBuiten')) {
          setValue(key, data[key.replace('ZakelijkBuiten', '')])
        }
      }
    }
  }

  const isTaskActive = watch(getPropertyName<CreateUpdateType>('actief')) === true
  const isGecombineerd = watch(getPropertyName<CreateUpdateType>('isGecombineerd')) === true
  const isTaskActiveZakelijk = watch(getPropertyName<CreateUpdateType>('actiefZakelijk')) === true
  const isGecombineerdZakelijk = watch(getPropertyName<CreateUpdateType>('isGecombineerdZakelijk')) === true

  return (
    <div>
      <FluentPanel isOpen={props.isOpen} headerText={title} onDismiss={onPanelDismiss} error={error} type={PanelType.extraLarge}>
        {tenantData?.heeftZakelijkeVerhuur === false && <Stack horizontal tokens={{ childrenGap: '24px' }}>
          <StackItem styles={{ root: { width: '50%' } }}>
            <VgtCheckbox label="Actief" name={getPropertyName<CreateUpdateType>('actief')} control={control} />
            <InnerColumn visible={true}
                         label="Binnen"
                         suffix=""
                         isTaskActive={isTaskActive}
                         onderhoudsspecialisme={onderhoudsspecialisme}
                         onderhoudstaken={onderhoudstaken}
                         meetkundigeEenheden={meetkundigeEenheden}
                         watch={watch} setValue={setValue}
                         inspectieSpecialismeId={inspectieSpecialismeId}
                         betalers={betalers}
                         dienstencomponenten={dienstencomponenten}
                         control={control} errors={errors}
            />

          </StackItem>
          <StackItem styles={{ root: { width: '50%' } }}>
            <VgtCheckbox disabled={!isTaskActive} name={getPropertyName<CreateUpdateType>('isGecombineerd')} label="Buiten is gelijk aan binnen" control={control} afterChange={onIsGecombineerdChanged} />
            <InnerColumn visible={!isGecombineerd}
                         label="Buiten"
                         suffix="Buiten"
                         isTaskActive={isTaskActive}
                         onderhoudsspecialisme={onderhoudsspecialisme}
                         onderhoudstaken={onderhoudstaken}
                         meetkundigeEenheden={meetkundigeEenheden}
                         watch={watch} setValue={setValue}
                         inspectieSpecialismeId={inspectieSpecialismeId}
                         betalers={betalers}
                         dienstencomponenten={dienstencomponenten}
                         control={control} errors={errors} />
          </StackItem>
        </Stack>
        }

        {tenantData?.heeftZakelijkeVerhuur === true && <Pivot styles={{root: {height: '70px'}}}>
            <PivotItem headerText="Prive" itemKey="prive" alwaysRender={true}>
              <Stack horizontal tokens={{ childrenGap: '24px' }}>
                <StackItem styles={{ root: { width: '50%' } }}>
                  <VgtCheckbox label="Actief" name={getPropertyName<CreateUpdateType>('actief')} control={control} />
                  <InnerColumn visible={true}
                               label="Binnen"
                               suffix=""
                               isTaskActive={isTaskActive}
                               onderhoudsspecialisme={onderhoudsspecialisme}
                               onderhoudstaken={onderhoudstaken}
                               meetkundigeEenheden={meetkundigeEenheden}
                               watch={watch} setValue={setValue}
                               inspectieSpecialismeId={inspectieSpecialismeId}
                               betalers={betalers}
                               dienstencomponenten={dienstencomponenten}
                               control={control} errors={errors}
                  />

                </StackItem>
                <StackItem styles={{ root: { width: '50%' } }}>
                  <VgtCheckbox disabled={!isTaskActive} name={getPropertyName<CreateUpdateType>('isGecombineerd')} label="Buiten is gelijk aan binnen" control={control} afterChange={onIsGecombineerdChanged} />
                  <InnerColumn visible={!isGecombineerd}
                               label="Buiten"
                               suffix="Buiten"
                               isTaskActive={isTaskActive}
                               onderhoudsspecialisme={onderhoudsspecialisme}
                               onderhoudstaken={onderhoudstaken}
                               meetkundigeEenheden={meetkundigeEenheden}
                               watch={watch} setValue={setValue}
                               inspectieSpecialismeId={inspectieSpecialismeId}
                               betalers={betalers}
                               dienstencomponenten={dienstencomponenten}
                               control={control} errors={errors} />
                </StackItem>
              </Stack>
            </PivotItem>
            <PivotItem headerText="Zakelijk" itemKey="zakelijk" alwaysRender={true}>
              <Stack horizontal tokens={{ childrenGap: '24px' }}>
                <StackItem styles={{ root: { width: '50%' } }}>
                  <VgtCheckbox label="Actief" name={getPropertyName<CreateUpdateType>('actiefZakelijk')} control={control} />
                  <InnerColumn visible={true}
                               label="Binnen zakelijk"
                               suffix="Zakelijk"
                               isTaskActive={isTaskActiveZakelijk}
                               onderhoudsspecialisme={onderhoudsspecialisme}
                               onderhoudstaken={onderhoudstaken}
                               meetkundigeEenheden={meetkundigeEenheden}
                               watch={watch} setValue={setValue}
                               inspectieSpecialismeId={inspectieSpecialismeId}
                               betalers={betalers}
                               dienstencomponenten={dienstencomponenten}
                               control={control} errors={errors}
                  />

                </StackItem>
                <StackItem styles={{ root: { width: '50%' } }}>
                  <VgtCheckbox disabled={!isTaskActive} name={getPropertyName<CreateUpdateType>('isGecombineerdZakelijk')} label="Buiten is gelijk aan binnen" control={control} afterChange={onIsGecombineerdZakelijkChanged} />
                  <InnerColumn visible={!isGecombineerdZakelijk}
                               label="Buiten zakelijk"
                               suffix="ZakelijkBuiten"
                               isTaskActive={isTaskActiveZakelijk}
                               onderhoudsspecialisme={onderhoudsspecialisme}
                               onderhoudstaken={onderhoudstaken}
                               meetkundigeEenheden={meetkundigeEenheden}
                               watch={watch} setValue={setValue}
                               inspectieSpecialismeId={inspectieSpecialismeId}
                               betalers={betalers}
                               dienstencomponenten={dienstencomponenten}
                               control={control} errors={errors} />
                </StackItem>
              </Stack>
            </PivotItem>
          </Pivot>
          }

          <FluentPanel.Footer>
            <OkCancelButtonStack isSubmitting={isSubmitting} isWriter={isReparatieboom} isLoading={isSubmitting ?? false}
                                 onOkClick={() => onSubmitClick()}
                                 onCancelClick={onPanelDismiss} />
          </FluentPanel.Footer>

        </FluentPanel>
          </div>
          )
        }