import { VheUpdateDto, VheVgeListDto, zodiosHooks } from 'api/ApiClient'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { getPropertyName } from '../../lib/interfaceUtils'
import React, { useEffect, useState } from 'react'
import { OkCancelButtonStack } from '../../components/OkCancelButtonStack/OkCancelButtonStack'
import VgtTextField from '../../components/VgtTextField'
import VgtDropdown from '../../components/FluentDropdown'
import { VgtPagedTable } from '../../components/VgtPagedTable/VgtPagedTable'
import { IColumn, Link, Pivot, PivotItem, Stack, StackItem } from '@fluentui/react'
import { createBooleanColumn, createColumn } from '../../lib/gridHelper'
import { usePagedParams } from '../../services/usePagedParams'
import FluentToggle from '../../components/FluentToggle'
import { useNavigate } from 'react-router-dom'
import { PageIdDefinition, PAGES } from '../../pages'
import DocumentList, { DocumentDoelType } from '../beheer/documents/DocumentList'
import VgtCheckbox from '../../components/FluentCheckbox'
import ReadOnlyField from '../../components/ReadOnlyField'

type CreateUpdateType = z.infer<typeof VheUpdateDto>;

const defaultVhe = {
  code: '',
  waardestelselOptieId: 0,
  eenheiddetailsoortId: 87,
  verhuurtypeOptieId: 0,
  adres: '',
  adresaanduiding: '',
  postcode: '',
  woonplaats: '',
  inExploitatie: null,
  uitExploitatie: null,
  activatiedatum: null,
  zorgwoning: false,
  isAdresOverschreven: false
} as CreateUpdateType

export const VheForm = ({ vheId, data, isLoading, isSubmitting, onSubmit, onCancel, onError }: {
  vheId: number,
  data?: CreateUpdateType,
  isLoading: boolean,
  isSubmitting: boolean,
  onSubmit: (values: CreateUpdateType) => void,
  onCancel: () => void
  onError?: (message: string) => void
}) => {

  const navigate = useNavigate()
  const [documentCount, setDocumentCount] = useState(0)

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors }
  } = useForm<CreateUpdateType>({
    resolver: zodResolver(VheUpdateDto), mode: 'all', defaultValues: defaultVhe
  })

  const { data: systemSettings, isLoading: systemSettingsLoading } = zodiosHooks.useGetSysteemInstellingen()

  useEffect(() => {
    if (data) {
      reset(data)
    } else {
      reset(defaultVhe)
    }
  }, [data])

  const isOverschreven = watch(getPropertyName<CreateUpdateType>('isAdresOverschreven'))
  // if (!isOverschreven) {
  //   setValue(getPropertyName<CreateUpdateType>('adres'), data?.vgeAdres)
  //   setValue(getPropertyName<CreateUpdateType>('postcode'), data?.vgePostcode)
  //   setValue(getPropertyName<CreateUpdateType>('woonplaats'), data?.vgeWoonplaats)
  // }
  //
  const handleItemInvoked = (id: number) => {
    navigate(PAGES[PageIdDefinition.vge.edit].route.replace(':id', id?.toString()))
  }

  const onBeforeSubmit = (values: CreateUpdateType) => {
    if (!values.isAdresOverschreven) {
      values.postcode = data?.postcode;
    }
    values.postcode = values.postcode?.toUpperCase()
    onSubmit(values)
  }

  type VgeListDtoType = z.infer<typeof VheVgeListDto>;
  const columns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('VGE Code', getPropertyName<VgeListDtoType>('id'), 'M', true, (item) => (
        <Link key={item.id} onClick={() => handleItemInvoked(item.id)}>
          {item.id}
        </Link>
      )),
      createColumn('Eenheiddetailsoort', getPropertyName<VgeListDtoType>('detailsoort'), 'L', false),
      createColumn('Adres', getPropertyName<VgeListDtoType>('adres'), 'XXL', false),
      createColumn('Adresaanduiding', getPropertyName<VgeListDtoType>('adresaanduiding'), 'M', false),
      createColumn('Postcode', getPropertyName<VgeListDtoType>('postcode'), 'M', false),
      createColumn('Woonplaats', getPropertyName<VgeListDtoType>('woonplaats'), 'M', false),
      createBooleanColumn('Collectief', getPropertyName<VgeListDtoType>('isCollectief'), 'M')
    ]
  }, [])
  const { validatedSearchParams, setPage, setOrder } = usePagedParams()

  const { data: eenheiddetailsoortOpties } = zodiosHooks.useGetEenheidDetailSoortdropdownoptions({}, { enabled: true })
  const { data: waardestelselOpties } = zodiosHooks.useGetVheswaardestelselOpties({}, { enabled: true })

  const { data: vgeData, isLoading: vgeIsLoading } = zodiosHooks.useGetVhesIdvges({
    params: {
      id: vheId
    },
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: 10
    }
  }, { enabled: vheId > 0 })

  const FieldErrorsToMessage = (errors: any) => {
    return Object.keys(errors).map((key) => {
      return key + ': ' + errors[key].message
    }).join(',\n')
  }

  return (
    <form onSubmit={handleSubmit(onBeforeSubmit, (data) => {
      if (onError) onError(FieldErrorsToMessage(data))
    })}>
      <Stack horizontal tokens={{ childrenGap: 30 }}>
        <StackItem grow={1}>
          <VgtTextField label="VHE Nummer" name={getPropertyName<CreateUpdateType>('code')} control={control} errors={errors} readOnly={systemSettings?.vheNummerActief === true} required={true} />
          <VgtTextField label="In exploitatiedatum"  name={getPropertyName<CreateUpdateType>('inexploitatie')} control={control} errors={errors} readOnly={true} />
          <VgtTextField label="Uit exploitatiedatum"  name={getPropertyName<CreateUpdateType>('uitexploitatie')} control={control} errors={errors} readOnly={true} />
          <VgtTextField label="Activatiedatum"  name={getPropertyName<CreateUpdateType>('activatiedatum')} control={control} errors={errors} readOnly={true} />
          <VgtTextField label="Verhuurder"  name={getPropertyName<CreateUpdateType>('verhuurderNaam')} control={control} errors={errors} readOnly={true} />
          <VgtDropdown label={'Eenheiddetailsoort'} name={getPropertyName<CreateUpdateType>('eenheiddetailsoortId')} control={control} options={eenheiddetailsoortOpties} required={true} errors={errors} />
          <VgtDropdown label={'Waarderingsstelsel'} name={getPropertyName<CreateUpdateType>('waardestelselOptieId')} control={control} options={waardestelselOpties} errors={errors} required={true} />
          <FluentToggle label={'Zorgwoning'} inlineLabel name={getPropertyName<CreateUpdateType>('zorgwoning')} control={control} errors={errors} />
        </StackItem>

        <StackItem grow={1}>
          <VgtCheckbox name={getPropertyName<CreateUpdateType>('isAdresOverschreven')} label={isOverschreven ? 'Adres handmatig overschreven' : 'Adres handmatig invoeren'} control={control} errors={errors} />
          <VgtTextField readOnly={!isOverschreven} label="Adres" name={getPropertyName<CreateUpdateType>('adres')} control={control} errors={errors} />
          {isOverschreven === true ? <VgtTextField  readOnly={!isOverschreven} label="Postcode" name={getPropertyName<CreateUpdateType>('postcode')} pattern={'[1-9][0-9]{3}[a-zA-Z]{2}'} control={control} errors={errors} /> :
          <ReadOnlyField title='Postcode' value={data?.postcodeDisplay} labelWidth={215} /> }
          <VgtTextField readOnly={!isOverschreven} label="Woonplaats" name={getPropertyName<CreateUpdateType>('woonplaats')} control={control} errors={errors} />
          <VgtTextField label="Adresaanduiding" name={getPropertyName<CreateUpdateType>('adresaanduiding')} control={control} errors={errors} />
        </StackItem>
      </Stack>

      <OkCancelButtonStack isSubmitting={isSubmitting}
                           isLoading={(isLoading || systemSettingsLoading) ?? false}
                           onOkClick={() => handleSubmit(onBeforeSubmit)}
                           onCancelClick={() => onCancel()} />

      {(vheId > 0) &&
        <Pivot>
          <PivotItem headerText="Vastgoedeenheden" itemCount={vgeData?.totalCount ?? 0}>
            <VgtPagedTable items={vgeData?.items ?? []}
                           isLoading={vgeIsLoading}
                           columns={columns}
                           pagingInfo={{
                             pageSize: validatedSearchParams.pageSize,
                             currentPage: validatedSearchParams.page,
                             totalCount: vgeData?.totalCount ?? 0,
                             totalPages: vgeData?.totalPages ?? 0,
                             hasNextPage: vgeData?.hasNextPage ?? false,
                             hasPreviousPage: vgeData?.hasPreviousPage ?? false,
                             sortKey: validatedSearchParams.sortKey,
                             sortDirection: validatedSearchParams.sortDirection
                           }}
                           getKey={(item: VgeListDtoType) => item?.id}
                           onPageChanged={setPage}
                           onSortChanged={setOrder} />
          </PivotItem>
          {vheId && (
            <PivotItem key="vhe_documenten" headerText="Media" itemCount={documentCount} itemKey="documents">
              <DocumentList doelType={DocumentDoelType.vhe} doelId={vheId} setDocumentCount={setDocumentCount}/>
            </PivotItem>
          )}
          <PivotItem headerText="Tijdlijn">

          </PivotItem>
        </Pivot>
      }
    </form>
  )
}