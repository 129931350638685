import { store } from 'store'
import { getApiToken } from 'lib/auth'
import { EventSourcePolyfill } from 'event-source-polyfill'

const handleSSE = async (
  uri: string,
  filenameDescription: string,
  setSheetName?: React.Dispatch<React.SetStateAction<string>>,
  setRowCount?: React.Dispatch<React.SetStateAction<number>>,
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const { apiEndpoint } = store.getState().app.configuration
  const token = await getApiToken()
  const endpoint = `${apiEndpoint}${uri}`

  if (setSheetName && setRowCount && setIsOpen)
  {
    setIsOpen(true)
  }

  try {
    const response = await fetch(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (response.headers.get('content-type')?.includes('text/event-stream')) {
      const eventSource = new EventSourcePolyfill(endpoint, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })

      eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data) as ExportUpdate

        if (data.isComplete) {
          // Decode base64 to Uint8Array
          const binaryString = atob(data.finalExcel!)
          const len = binaryString.length
          const bytes = new Uint8Array(len)
          for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i)
          }

          // Download the file
          const blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = `${data.sheetName}.xlsx`
          a.click()
          window.URL.revokeObjectURL(url)
          eventSource.close()
          if (setSheetName && setRowCount && setIsOpen)
          {
            setSheetName('')
            setRowCount(0)
            setIsOpen(false)
          }
        } else {
          // Update the status
          if (setSheetName && setRowCount) {
            setSheetName(data.sheetName)
            setRowCount(data.rowCount)
          }
        }
      }

      eventSource.onerror = (error) => {
        console.error('EventSource failed:', error)
        eventSource.close()
        if (setSheetName && setRowCount && setIsOpen)
        {
          setSheetName('')
          setRowCount(0)
          setIsOpen(false)
        }
      }
    } else {
      // Handle direct file download
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = filenameDescription
      a.click()
      window.URL.revokeObjectURL(url)
      if (setSheetName && setRowCount && setIsOpen)
      {
        setSheetName('')
        setRowCount(0)
        setIsOpen(false)
      }
    }
  } catch (error) {
    console.error('Request failed:', error)
    if (setSheetName && setRowCount && setIsOpen)
    {
      setSheetName('')
      setRowCount(0)
      setIsOpen(false)
    }
  }
}

export default handleSSE

interface ExportUpdate {
  sheetName: string
  rowCount: number
  isComplete: boolean
  finalExcel: string | null
}