import React, { useEffect, useRef, useState } from 'react'
import {
  DefaultButton,
  DetailsList,
  IButtonStyles,
  IColumn,
  IDropdownOption,
  IStackTokens,
  Pivot,
  PivotItem, ScrollablePane,
  SelectionMode,
  Spinner,
  SpinnerSize,
  Stack,
  StackItem
} from '@fluentui/react'
import { VgeWoningwaarderingUpdateDto, VoorzieningenListDto, zodiosHooks } from '../../../api/ApiClient'
import { VgeRuimtes } from '../ruimtes/VgeRuimtes'
import { z } from 'zod'
import { getPropertyName } from '../../../lib/interfaceUtils'
import { SectionDropDown, SectionHeader, SectionLine, SectionTextEdit } from '../../../components/Section'
import { createColumn } from '../../../lib/gridHelper'


type CreateOrUpdateType = z.infer<typeof VgeWoningwaarderingUpdateDto>;
type VoorzieningenListDtoType = z.infer<typeof VoorzieningenListDto>;

export const stackTokens: IStackTokens = { childrenGap: 20 }

const WWSCard = ({ vgeId }) => {
  const [updateDto, setUpdateDto] = React.useState<CreateOrUpdateType>({} as CreateOrUpdateType)

  const { data, invalidate, isLoading } = zodiosHooks.useGetVgeVgeIdwoningwaardering(
    { params: { vgeId: vgeId } },
    {
      enabled: vgeId !== 0
    }
  )
  const { data: buitenruimtes } = zodiosHooks.useGetBuitenruimtesOpties()
  const { data: vgeVoorzieningen } = zodiosHooks.useGetVgeVgeIdvoorzieningen(
    { params: { vgeId: vgeId } },
    {
      enabled: vgeId !== 0
    }
  )
  const { mutate, isLoading: isUpdating } = zodiosHooks.usePutVgeVgeIdwoningwaardering({ params: { vgeId: vgeId } }, {
    onSuccess: () => invalidate(),
    onError: (error) => alert(error)
  })
  const buttonStyles: IButtonStyles = {
    root: {
      position: 'fixed',
      right: '30px',
      top: '245px',
      zIndex: 1000
    }
  }
  const buttonStylesSave: IButtonStyles = {
    root: {
      position: 'fixed',
      right: '140px',
      top: '245px',
      zIndex: 1000
    }
  }

  const listRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(400)
  useEffect(() => {
    const handleResize = () => {
      const headersHeight = 0
      const availableHeight = window.innerHeight - headersHeight

      if (listRef.current) {
        setHeight(availableHeight - listRef.current.offsetTop)
      } else {
        setHeight(availableHeight - 300)
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])
  useEffect(() => {
    resetChanges()
  }, [data])

  const hasChanged = () => {
    return (
      updateDto.priveBuitenruimte !== data?.priveBuitenruimte ||
      updateDto.extraPuntenGemeenschappelijkeBuitenruimte !== data?.extraPuntenGemeenschappelijkeBuitenruimte ||
      updateDto.aantalVvi !== data?.aantalVVI ||
      updateDto.aantalVvc !== data?.aantalVVC ||
      updateDto.aantalVoi !== data?.aantalVOI ||
      updateDto.aantalVoc !== data?.aantalVOC
    )
  }

  const onSelect = (id: string, item?: IDropdownOption) => {
    if (!item) {
      return
    }
    setUpdateDto({ ...updateDto, [id]: item.key })
  }

  const onChange = (id: string, value: number | undefined) => {
    if (!value || value < 0) {
      setUpdateDto({ ...updateDto, [id]: null })
      return
    }

    setUpdateDto({ ...updateDto, [id]: value })
  }

  const saveChanges = () => {
    mutate(updateDto)
  }
  const resetChanges = () => {
    if (data) {
      setUpdateDto({
        priveBuitenruimte: data.priveBuitenruimte ?? 0,
        extraPuntenGemeenschappelijkeBuitenruimte: data.extraPuntenGemeenschappelijkeBuitenruimte,
        aantalVvi: data.aantalVVI,
        aantalVvc: data.aantalVVC,
        aantalVoi: data.aantalVOI,
        aantalVoc: data.aantalVOC
      })
    }
  }

  const voorzieningenColumns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('Type', getPropertyName<VoorzieningenListDtoType>('type'), 'M', true),
      createColumn('Code', getPropertyName<VoorzieningenListDtoType>('code'), 'L', true),
      createColumn('Naam', getPropertyName<VoorzieningenListDtoType>('naam'), 'XL', false),
      createColumn('Omschrijving', getPropertyName<VoorzieningenListDtoType>('omschrijving'), 'XL', false)
    ]
  }, [])

  return (
    <>
      {hasChanged() &&
        <div>
          <DefaultButton text="Opslaan" primary styles={buttonStylesSave} onClick={saveChanges}>{isUpdating &&
            <Spinner />}</DefaultButton>
          <DefaultButton text="Annuleren" primary styles={buttonStyles} onClick={resetChanges} /></div>
      }
      <Pivot style={{ marginTop: '-12px' }}>
        <PivotItem headerText="Algemeen" itemKey={'Algemeen'}>
          {isLoading &&
            <div style={{ paddingTop: 20 }}><Spinner size={SpinnerSize.large}></Spinner></div>}
          {data &&
            <div ref={listRef} style={{ position: 'relative', height: `${height}px`, overflow: 'auto' }}>
              <ScrollablePane styles={{ contentContainer: { paddingRight: '20px' } }}>
                <Stack horizontal tokens={stackTokens} style={{ display: 'flex', justifyContent: 'space-between', marginRight: '24px' }}>
                  <StackItem style={{ width: '46%' }}>
                    <SectionHeader title="Verwarming">
                      <SectionLine title="Warmtelevering" value={data.warmteLevering} />
                      {data.aantalVerwarmdeVertrekkenIndividueel === null &&
                        <SectionTextEdit id={getPropertyName<CreateOrUpdateType>('aantalVvi')}
                                         title="Aantal verwarmde vertrekken individueel" value={updateDto.aantalVvi}
                                         onChange={onChange} />}
                      {data.aantalVerwarmdeVertrekkenIndividueel !== null &&
                        <SectionLine title="Aantal verwarmde vertrekken individueel"
                                     value={data.aantalVerwarmdeVertrekkenIndividueel.toLocaleString('nl-NL')} />}
                      {data.aantalVerwarmdeOverigeRuimtenIndividueel === null &&
                        <SectionTextEdit id={getPropertyName<CreateOrUpdateType>('aantalVoi')}
                                         title="Aantal verwarmde overige ruimtes individueel" value={updateDto.aantalVoi}
                                         onChange={onChange} />}
                      {data.aantalVerwarmdeOverigeRuimtenIndividueel !== null &&
                        <SectionLine title="Aantal verwarmde overige ruimtes individueel"
                                     value={data.aantalVerwarmdeOverigeRuimtenIndividueel.toLocaleString('nl-NL')} />}
                      {data.aantalVerwarmdeVertrekkenCollectief === null &&
                        <SectionTextEdit id={getPropertyName<CreateOrUpdateType>('aantalVvc')}
                                         title="Aantal verwarmde vertrekken collectief" value={updateDto.aantalVvc}
                                         onChange={onChange} />}
                      {data.aantalVerwarmdeVertrekkenCollectief !== null &&
                        <SectionLine title="Aantal verwarmde vertrekken collectief"
                                     value={data.aantalVerwarmdeVertrekkenCollectief?.toLocaleString('nl-NL')} />}
                      {data.aantalVerwarmdeOverigeRuimtenCollectief === null &&
                        <SectionTextEdit id={getPropertyName<CreateOrUpdateType>('aantalVoc')}
                                         title="Aantal verwarmde overige ruimtes collectief" value={updateDto.aantalVoc}
                                         onChange={onChange} />}
                      {data.aantalVerwarmdeOverigeRuimtenCollectief !== null &&
                        <SectionLine title="Aantal verwarmde overige ruimtes collectief"
                                     value={data.aantalVerwarmdeOverigeRuimtenCollectief?.toLocaleString('nl-NL')} />}
                    </SectionHeader>
                    <SectionHeader title="Energieprestatie">
                      <SectionLine title="Berekeningstype" value={data.epBerekeningswijze} />
                      <SectionLine title="Labelletter" value={data.epEnergieLabel} />
                      <SectionLine title="El geldig voor WWS" value={data.epEnergieprestatie} valueAlignment={'right'} />
                      <SectionLine title="Opnamedatum" value={data.epOpnameDatum} />
                      <SectionLine title="Meting geldig tot " value={data.epGeldigTot} />
                      <SectionLine title="Eengezins / meergezins" value={data.eensgezinsMeergezins} />
                      <SectionLine title="Bouwjaar" value={data.bouwjaar} />
                    </SectionHeader>
                    <SectionHeader title="Woonvoorziening voor gehandicapten">
                      <SectionLine title="Woonvoorziening voor gehandicapten" value={data.woonvoorzieningGehandicapten}
                                   valueAlignment={'right'} />
                    </SectionHeader>
                    <SectionHeader title="Nieuwbouwopslag">
                        <SectionLine title="Datum bouw gestart" value={data.datumBouwGestart} />
                        <SectionLine title="Datum ingebruikname" value={data.datumIngebruikname} />
                    </SectionHeader>
                    <SectionHeader title="Overige kenmerken">
                      <SectionLine title="Zolder zonder vaste trap" value={data.zolderZonderVasteTrapNaam} />
                    </SectionHeader>
                  </StackItem>
                  <StackItem style={{ width: '49%' }}>
                    <SectionHeader title="Privé-buitenruimten">
                      <SectionDropDown id={getPropertyName<CreateOrUpdateType>('priveBuitenruimte')}
                                       title="Privé buitenruimte" value={updateDto.priveBuitenruimte} onSelect={onSelect}
                                       options={buitenruimtes ?? []} />
                      <SectionLine title="Carport" value={data.carport} />
                      <SectionLine title="Garage" value={data.garage} />
                    </SectionHeader>
                    <SectionHeader title="Gemeenschappelijke ruimten en voorzieningen">
                      <SectionTextEdit id={getPropertyName<CreateOrUpdateType>('extraPuntenGemeenschappelijkeBuitenruimte')}
                                       title="Extra punten i.v.m. gemeenschappelijke ruimte"
                                       value={updateDto.extraPuntenGemeenschappelijkeBuitenruimte} onChange={onChange}
                                       fractionDigits={2} />
                    </SectionHeader>
                    <SectionHeader title="WOZ-Waardering">
                      <SectionLine title="WOZ-Peildatum" value={data.wozPeildatum} />
                      <SectionLine title="WOZ-Waarde" value={data.wozWaarde} valueAlignment={'right'} />
                      <SectionLine title='Taxatiewaarde' value={data.taxatiewaarde} />
                    </SectionHeader>                    
                    <SectionHeader title="Monumenten">
                      <SectionLine title="Rijksmonument" value={data.rijksmonument} />
                      <SectionLine title="Beschermd stads- of dorpsgezicht" value={data.beschermdeStadsgezicht} />
                      <SectionLine title="Gemeentelijk" value={data.gemeentelijk} />
                      <SectionLine title="Provinciaal" value={data.provinciaal} />
                    </SectionHeader>
                    <SectionHeader title="Bijzondere voorzieningen">
                      <SectionLine title="Zorgwoning" value={data.zorgwoning} />
                      <SectionLine title="Videofoon" value={data.videofoon} />
                    </SectionHeader>
                  </StackItem>
                </Stack>
              </ScrollablePane>
            </div>}
        </PivotItem>
        <PivotItem headerText="Ruimtes en vertrekken" itemKey={'ruimtes'} style={{ padding: 10 }}>
          <VgeRuimtes vgeId={vgeId} />
        </PivotItem>
        <PivotItem headerText="Voorzieningen" itemKey={'voorzieningen'} style={{ padding: 10 }}>
          <DetailsList
            items={vgeVoorzieningen ?? []}
            columns={voorzieningenColumns}
            selectionMode={SelectionMode.none} />
        </PivotItem>
      </Pivot>
    </>
  )
}

export default WWSCard