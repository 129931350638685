import {
  Stack,
  StackItem,
  TooltipHost,
  IconButton,
  IDropdownOption,
  mergeStyleSets,
  IDropdownStyles
} from '@fluentui/react'
import VgtCheckbox from 'components/FluentCheckbox'
import VgtDropdown from 'components/FluentDropdown'
import VgtTextField from 'components/VgtTextField'
import { getPropertyName } from 'lib/interfaceUtils'
import { register } from 'serviceWorker'
import { IInstallatie } from '../installatie/installatieTypes'
import { useSelector } from 'react-redux'
import {
  fetchRechtspersonen,
} from '../relaties/rechtspersoon/rechtspersoonSlice'
import { useAppDispatch } from 'store'
import {
  fetchAll as fetchDefectlocaties,
  getDropDownValuesForDefectlocaties
} from '../lemdo/defectLocatie/defectLocatieSlice'
import {
  fetchMeetkundigeEenheden,
  getDropDownValues as getEenheden
} from 'features/beheer/basis/meetkundigeEenheden/meetkundigeEenheidSlice'
import {
  fetchVgeContracten,
  selectAll as selectOnderhoudscontracten
} from 'features/vge/onderhoudsContract/vgeContractSlice'
import { IOnderhoudsContract } from '../onderhoudscontract/onderhoudsContractSlice'
import { useEffect, useMemo, useState } from 'react'
import { zodiosHooks } from '../../../api/ApiClient'
import InputCard from '../../../components/InputCard'

interface ICartotheekDetailAlgemeenProps {
  installatie: IInstallatie
  control: any
  errors: any

  setValue(name: string, value: any): void
}

export const CartotheekDetailAlgemeen = ({
                                           installatie,
                                           control,
                                           errors,
                                           setValue
                                         }: ICartotheekDetailAlgemeenProps) => {
  const dispatch = useAppDispatch()
  const collectief = installatie.vgeId === undefined
  const { data: currentTenant } = zodiosHooks.useGetApiTenant()
  const { data: beheerderDropdownOptions } = zodiosHooks.useGetRechtspersonendropdownoptions({ queries: { } }, { enabled: true })
  const defaultBeheerderKey = beheerderDropdownOptions && beheerderDropdownOptions.length > 0 ? beheerderDropdownOptions[0].key : undefined

  if (installatie.beheerderId === undefined && defaultBeheerderKey !== undefined) {
    setValue('beheerderId', defaultBeheerderKey)
  }

  const { data: verbeteringTypes } = zodiosHooks.useGetWoningverbeteringwoningverbeteringTypes({})
  const woningVerbeteringTypeDropdownOptions = !verbeteringTypes ? [] : verbeteringTypes.map(x => {
    return {
      key: x.woningVerbeteringTypeId,
      text: x.naam
    } as IDropdownOption
  })

  const { data: ruimteSoortErpDropdownOptions } = zodiosHooks.useGetRuimtesoortErpopties();

  const optionUnSelected = {
    key: -1,
    text: '---'
  } as IDropdownOption

  const createContractOptions = (contracten: IOnderhoudsContract[]) => {
    const options = contracten.map(c => {
      return {
        key: c.id,
        text: `${c.contractNummer}: ${c.contractOmschrijving} (${c.contractBeheerder})`
      } as IDropdownOption
    })
    return [optionUnSelected, ...options]
  }

  useEffect(() => {
    if (defectlocatieOptions.length === 0) {
      dispatch(fetchDefectlocaties())
    }
    dispatch(fetchRechtspersonen({ filter: '' }))
    if (eenheidOptions.length === 0) dispatch(fetchMeetkundigeEenheden())
  }, [])

  const { data: ruimteOpties } = zodiosHooks.useGetVgeVgeIdruimtesAsOptions({ params: { vgeId: installatie.vgeId ?? 0 } }, { enabled: true })

  useEffect(() => {
    if (installatie.vgeId) {
      dispatch(fetchVgeContracten(installatie.vgeId))
    }
  }, [installatie.vgeId])

  const contracten = useSelector(selectOnderhoudscontracten)
  const optionsContracten = useMemo(() => createContractOptions(contracten), [contracten])
  const [contractText, setContractText] = useState<string>()
  const onChangedContract = (contractId?: string | number | undefined) => {
    if (contractId && contractId !== -1) {
      const option = optionsContracten.find(c => c.key === contractId)
      if (option) setContractText(option.text)
      else setContractText(undefined)
    } else {
      setContractText(undefined)
    }
  }
  const defectlocatieOptions = useSelector(getDropDownValuesForDefectlocaties)

  const eenheidOptions = useSelector(getEenheden)

  const classNames = mergeStyleSets({
    stackItem: {
      border: `1px solid #efefef`,
      margin: '10px 0'
    }
  })

  const getTextFieldStyles = (labelWidth: string | number, title?: number): Partial<IDropdownStyles> => {
    return {
      root: {
        padding: '0px 0px 0px 12px',
        display: 'flex',
        width: '98%'
      },
      label: {
        fontWeight: 'Normal',
        width: labelWidth,
        flexGrow: '0',
        flexShrink: '0'
      },
      title: {
        borderWidth: '0px',
        paddingLeft: '4px',
        width: title ?? '98%'
      },
      dropdown: {
        width: '100%',
        fontWeight: 600
      }
    }
  }


  zodiosHooks.useGetElementElementDetailsId({ params: { id: installatie.elementdetailId ?? 0 } }, {
    onSuccess: (data) => {
      setValue('categorieId', data.elementCategoryId ?? 0)
      setValue('categorieDisplay', data.elementCategoryNaam ?? '')
      setValue('elementId', data.elementId)
      setValue('elementDisplay', data.elementNaam ?? '')
      setValue('elementdetailDisplay', data.elementDetailNaam ?? '')
      setValue('materiaalSoortId', data.materiaalSoortId ?? null)
      setValue('materiaalSoortDisplay', data.materiaalSoortNaam ?? '')
    },
    enabled: installatie.elementdetailId !== undefined && (installatie.categorieId === undefined || installatie.elementId === undefined || installatie.materiaalSoortId === undefined)
  })



  return (
    <Stack style={{ width: '99%', marginBottom: 20 }}>
      <StackItem>
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <InputCard width="49%" title="Algemeen">
            <VgtTextField label="Elementcategorie" name={getPropertyName<IInstallatie>('categorieDisplay')} control={control} errors={errors} readOnly={true} />
            <VgtTextField label="Element" name={getPropertyName<IInstallatie>('elementDisplay')} control={control} errors={errors} readOnly={true} />
            <VgtTextField label="Elementdetail" name={getPropertyName<IInstallatie>('elementdetailDisplay')} control={control} errors={errors} readOnly={true} />
            <VgtTextField label="Materiaalsoort" name={getPropertyName<IInstallatie>('materiaalSoortDisplay')} control={control} errors={errors} readOnly={true} />
            <VgtTextField label="Nl-sfb code" name={getPropertyName<IInstallatie>('nlSfbCode')} control={control} errors={errors} readOnly={true} />
            <VgtTextField label="Nl-sfb" name={getPropertyName<IInstallatie>('nlSfbOmschrijving')} control={control} errors={errors} readOnly={true} />
            {!collectief && (
              <VgtDropdown options={ruimteOpties}
                           name={getPropertyName<IInstallatie>('vgeRuimteId')} label="Ruimte"
                           control={control} errors={errors}
                           styles={getTextFieldStyles(215)} />
            )}
            <VgtTextField label="Locatieaanduiding"
                          name={getPropertyName<IInstallatie>('locatieaanduiding')}
                          control={control}
                          errors={errors}
                          labelWidth={210}
            />
            <VgtDropdown label="Woningverbetering"
                         name={getPropertyName<IInstallatie>('woningVerbeteringType')}
                         control={control}
                         errors={errors}
                         defaultSelectedKey={installatie.woningVerbeteringType ?
                           installatie.woningVerbeteringType : undefined}
                         options={woningVerbeteringTypeDropdownOptions}
                         styles={getTextFieldStyles(215)}
            />
            <VgtCheckbox label="ZAV onderhoud overgenomen"
                         name={getPropertyName<IInstallatie>('zavOnderhoud')}
                         control={control}
                         errors={errors}
            />
            {collectief && (
              <VgtDropdown options={defectlocatieOptions}
                           name={getPropertyName<IInstallatie>('defectlocatieId')}
                           label="Defectlocatie"
                           control={control}
                           errors={errors}
              />
            )}
          </InputCard>
          <InputCard width="49%" title="">
            <VgtCheckbox label="Asbest" name={getPropertyName<IInstallatie>('asbest')}
                         register={register}
                         control={control}
                         errors={errors}
            />
            <VgtCheckbox label="Collectief"
                         name={getPropertyName<IInstallatie>('collectief')}
                         register={register}
                         control={control}
                         errors={errors}
                         defaultValue={collectief}
            />
            <VgtDropdown label="Beheerder"
                         name={getPropertyName<IInstallatie>('beheerderId')}
                         register={register}
                         defaultSelectedKey={installatie.beheerderId ?? defaultBeheerderKey}
                         control={control}
                         errors={errors}
                         options={beheerderDropdownOptions}
                         styles={getTextFieldStyles(220)}
            />
            <VgtTextField label="Externe referentie kostenplaats"
                          name={getPropertyName<IInstallatie>('referentieKostenplaats')}
                          register={register}
                          control={control}
                          errors={errors}
            />
            {currentTenant?.isEmpireKlant && (
              <VgtDropdown
                            label="Ruimtesoort ERP"
                            name={getPropertyName<IInstallatie>('ruimteSoortErpId')}
                            register={register}
                            defaultSelectedKey={installatie.ruimteSoortErpId}
                            control={control}
                            errors={errors}
                            options={ruimteSoortErpDropdownOptions}
                            styles={getTextFieldStyles(220)}
                            required={collectief || currentTenant?.isEmpireKlant}
              />
            )}
            <VgtTextField label="Bron"
                          name={getPropertyName<IInstallatie>('bron')}
                          register={register}
                          control={control}
                          errors={errors}
            />
            <VgtTextField label="Externe referentie ketenpartner"
                          name={getPropertyName<IInstallatie>('referentieExtern')}
                          register={register}
                          control={control}
                          errors={errors}
                          defaultValue={installatie.referentieExtern}
            />
            <VgtTextField label="Opmerking" name={getPropertyName<IInstallatie>('opmerking')}
                          register={register}
                          control={control}
                          errors={errors}
            />
            <VgtTextField label="Cartotheek-item Nr." name={getPropertyName<IInstallatie>('id')} control={control} errors={errors} readOnly={true} />
          </InputCard>
        </Stack>
      </StackItem>
      <StackItem className={classNames.stackItem}>
        <Stack horizontal>
          <TooltipHost content={contractText} id="tooltipContractId">
            <VgtDropdown label="Onderhoudscontract" name={getPropertyName<IInstallatie>('contractId')}
                         register={register}
                         defaultSelectedKey={installatie.contractId}
                         control={control}
                         errors={errors}
                         options={optionsContracten}
                         onChange={onChangedContract}
                         dropdownWidth={450}
                         aria-describedby="tooltipContractId"
                         styles={getTextFieldStyles(220, 450)}
            />
          </TooltipHost>
          <IconButton aria-label="Wis het onderhoudscontract" iconProps={{ iconName: 'Cancel' }}
                      style={{ marginLeft: 5 }}
                      onClick={() => setValue(getPropertyName<IInstallatie>('contractId'), optionUnSelected.key)} />
        </Stack>
      </StackItem>
    </Stack>
  )
}