import React from 'react'
import { Dialog, DialogType, Spinner, SpinnerSize, Text } from '@fluentui/react'

interface StatusDialogProps {
  open: boolean
  statusText: string
  rowCount: number
}

const StatusDialog: React.FC<StatusDialogProps> = ({ open, statusText, rowCount }) => {
  return (
    <Dialog
      hidden={!open}
      dialogContentProps={{
        type: DialogType.normal,
        title: 'Verwerken data'
      }}
      modalProps={{
        isBlocking: true
      }}
    >
      <div style={{ padding: '20px', textAlign: 'center' }}>
        <Spinner size={SpinnerSize.large} label="Even geduld aub..." />
        <div style={{ margin: '20px 0' }}>
          <Text variant="large">{statusText}</Text>
        </div>
        {rowCount > 0 && (
          <div style={{ margin: '20px 0' }}>
            <Text variant="medium">Aantal verwerkt: {rowCount}</Text>
          </div>
        )}
      </div>
    </Dialog>
  )
}

export default StatusDialog