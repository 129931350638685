import {
  Callout,
  DefaultButton,
  DetailsList,
  DirectionalHint,
  getTheme,
  HoverCard,
  IColumn,
  IDetailsListStyles,
  IExpandingCardProps,
  IStackTokens,
  ITextFieldStyles,
  ITooltipHostStyles,
  ITooltipProps,
  Label,
  Link,
  mergeStyleSets,
  Panel,
  PanelType,
  ScrollablePane,
  ScrollbarVisibility,
  Shimmer,
  ShimmerElementsGroup,
  ShimmerElementType,
  Spinner,
  SpinnerSize,
  Stack,
  StackItem,
  Text,
  TextField,
  TooltipHost
} from '@fluentui/react'
import { useBoolean, useId } from '@fluentui/react-hooks'
import InputCard from 'components/InputCard'
import {
  fetchAll as fetchAllEnheidDetailSoorten,
  selectAll as selectAllEenheidDetailSoort
} from 'features/beheer/lemdo/eenheidDetailSoort/eenheidDetailSoortSlice'
import { createColumn } from 'lib/gridHelper'
import { getPropertyName } from 'lib/interfaceUtils'
import { PageIdDefinition, PAGES } from 'pages'
import { FC, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { getAdresseerbaarobject, getPandData, IAdresseerbaarobject, INummeraanduiding } from 'services/bagService'
import { useAppDispatch } from 'store'
import { ITenant } from 'store/actions/app/tenantSlice'
import adresseerbaarObjectIcon from './icons/Vastgoedtabel_Icons_BAG-informatie.png'
import locatie from './icons/Vastgoedtabel_Icons_BAG-locatie.png'
import cbs from './icons/Vastgoedtabel_Icons_CBS.png'
import gerelateerd from './icons/Vastgoedtabel_Icons_Gerelateerd.png'
import monumenten from './icons/Vastgoedtabel_Icons_Monumenten.png'
import panden from './icons/Vastgoedtabel_Icons_Vastgoedeenheid-locatie.png'
import details from './icons/Vastgoedtabel_Icons_VGE-details.png'
import { createAddressTextFromVge } from '../../lib/addressHelper'
import { IPand, IVge, selectAll } from './vgeSlice'
import { GooglePhoto } from './GooglePhoto'
import { zodiosHooks } from '../../api/ApiClient'
import { getTitleAndMessage } from '../../services/HandleError'
import ErrorMessageBar from '../../components/ErrorMessageBar/ErrorMessageBar'
import { DocumentDoelType } from '../beheer/documents/DocumentList'
import { HuurcontractbepalingenTooltip } from '../beheer/huurcontractbepaling/HuurcontractbepalingShow'
import EnergyLabel, { EnergyLabelType } from '../../components/EnergyLabel'
import { availableUrls } from '../../routing/AvailableUrls'
import LinkReadOnlyField from '../../components/LinkReadOnlyField'

declare global {
  interface Window {
    StreetsmartApi: () => StreetsmartApi
  }

  interface StreetsmartApi {
    init: ({ StreetsmartOptions }) => Promise<any>
    destroy: ({ StreetsmartOptions }) => void
  }

  interface StreetsmartOptions {
    targetElement: HTMLElement
    username: string
    password: string
    apiKey: string
    locale: string
    overlayDrawDistance: number
    addressSettings: { locale: string; database: string }
  }
}

const labelStyles: Partial<ITextFieldStyles> = {
  wrapper: {
    borderBottom: '1px solid #efefef'
  },
  subComponentStyles: {
    label: {
      root: { width: 170, fontWeight: 'normal', cursor: 'pointer' }
    }
  },
  field: {
    fontWeight: 600,
    cursor: 'pointer'
  },
  suffix: {
    background: ''
  }
}

const theme = getTheme()

const styles = mergeStyleSets({
  callout: {
    width: 416,
    padding: '20px 24px',
    background: theme.semanticColors.bodyBackground
  }
})

const calloutProps = { gapSpace: 0 }
const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } }

export interface IAdresseerbaarObjectProps {
  tenant?: ITenant
  vge?: IVge
  onOpenPivot?: (key: string) => void
  labelletter?: EnergyLabelType
}

interface IReadonlyFieldProps {
  title: string
  value: any
}

interface IShimmedReadonlyFieldProps extends IReadonlyFieldProps {
  isLoaded: boolean
  height?: number
}

const AdresseerbarReadOnlyField: FC<IReadonlyFieldProps> = (props: IReadonlyFieldProps) => {
  return <TextField autoComplete="off" styles={labelStyles} label={props.title} underlined readOnly value={props.value}
                    placeholder={'---'} />
}

const getCustomElements = (backgroundColor?: string) => {
  return (
    <div style={{ display: 'flex' }}>
      <ShimmerElementsGroup
        backgroundColor={backgroundColor}
        flexWrap
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.line, width: '80%', height: 16, verticalAlign: 'center' },
          { type: ShimmerElementType.gap, width: '100%', height: 16 }
        ]}
      />
    </div>
  )
}

const AdresReadOnlyField: FC<IShimmedReadonlyFieldProps> = (props: IShimmedReadonlyFieldProps) => {
  return (
    <Shimmer customElementsGroup={getCustomElements()} isDataLoaded={props.isLoaded}>
      <AdresseerbarReadOnlyField {...props} />
    </Shimmer>
  )
}

export const stackTokens: IStackTokens = { childrenGap: '20 20' }

const AdresseerbaarObject: FC<IAdresseerbaarObjectProps> = (props: IAdresseerbaarObjectProps) => {
  const { vge, tenant } = props
  const gebruiksdoelTooltipId = useId()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const items = useSelector(selectAll).filter(x => x.id !== vge?.id)
  const eenheidDetailSoorten = useSelector(selectAllEenheidDetailSoort)
  const [image, setImage] = useState<string>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const streetsmartElement = useRef<HTMLDivElement>(null)
  const [error, setError] = useState<string>()
  const [pandData, setPandData] = useState<IPand[]>([])

  const [bagObject, setBagObject] = useState<IAdresseerbaarobject>({
    type: '',
    voorkomen: {} as any,
    status: '',
    identificatie: '',
    gebruiksdoelen: '',
    oppervlakte: 0,
    documentnummer: '',
    documentdatum: '',
    maaktDeelUitVan: {} as any,
    heeftAlsNevenAdres: {} as any,
    geometrie: {} as any,
    heeftAlsHoofdAdres: {} as any,
    geconstateerd: ''
  } as IAdresseerbaarobject)
  const [isAdresLoading, setIsAdresLoading] = useState<boolean>(false)

  useEffect(() => {
    dispatch(fetchAllEnheidDetailSoorten())

    return dismissAndDestroyStreetsmart()
  }, [])

  useEffect(() => {
    if (vge?.baGid) {
      setIsAdresLoading(true)
      getAdresseerbaarobject(vge?.baGid)
        .then(bagObject => {
          setBagObject(bagObject)
        })
        .finally(() => setIsAdresLoading(false))
    } else {
      setBagObject({} as IAdresseerbaarobject)
    }

    if (vge?.pId) {
      getPandData(vge?.pId)
        .then(pandObject => {
          setPandData([pandObject])
        })
    }

  }, [vge])

  useEffect(() => {
    setIsLoading(true)
    setImage(undefined)
    if (tenant?.cyclomediaApiKey && vge?.id) {
      const url = `https://atlas.cyclomedia.com/panoramarendering/RenderByAddress/NL/${vge.str} ${vge.nr}%20${vge.wpl}%20${vge.pc}/?width=1024&height=768&hfov=110&apiKey=${tenant?.cyclomediaApiKey}`
      const authorization = btoa(tenant?.cyclomediaUsername + ':' + tenant?.cyclomediaPassword)
      fetch(url, {
        method: 'GET',
        headers: {
          Authorization: 'Basic ' + authorization
        }
      }).then(response => {
        response.arrayBuffer().then(buffer => {
          const url = window.URL.createObjectURL(new Blob([buffer]))
          setImage(url)
        })
      })
    }
  }, [tenant, vge, vge?.id])

  useEffect(() => {
    if (image) {
      setTimeout(() => {
        setIsLoading(false)
      }, 500)
    }
  }, [image])

  const NavigateToAddress = query => {
    const options = {
      viewerType: ['PANORAMA'],
      srs: 'EPSG:3857',
      panoramaViewer: {
        closable: false,
        maximizable: true,
        replace: true,
        recordingsVisible: true,
        navbarVisible: true,
        timeTravelVisible: true,
        measureTypeButtonVisible: true
      },
      obliqueViewer: {
        closable: true,
        maximizable: true,
        navbarVisible: true,
        timeTravelVisible: true
      }
    }

    return global.StreetSmartApi.open(query, options)
      .then(function() {
      })
      .catch(function(reason) {
        console.log('Failed to create component(s) through API:', reason)
      })
  }

  const InitCyclomedia = address => {
    if (tenant?.cyclomediaApiKey) {
      global.StreetSmartApi.init({
        targetElement: streetsmartElement.current,
        username: tenant?.cyclomediaUsername,
        password: tenant?.cyclomediaPassword,
        apiKey: tenant?.cyclomediaApiKey,
        srs: 'EPSG:3857',
        locale: 'nl',
        overlayDrawDistance: 30,
        addressSettings: {
          locale: 'nl',
          database: 'Nokia'
        }
      }).then(
        function() {
          NavigateToAddress(address)
        },
        function(err) {
          console.error('Api: init: failed. Error: ', err)
          alert('Failed to initialize StreetSmart API: ' + err)
        }
      )
    }
  }

  const CycloMediaPhoto: FC<{ isLoading: boolean; image: string | undefined }> = props => {
    return (
      <>
        {props.isLoading && (
          <div className="spinnerWrapper" style={{ height: '270px', width: '397px' }}>
            <Spinner size={SpinnerSize.medium} />
          </div>
        )}
        {!props.isLoading && (
          <img
            style={{
              cursor: 'pointer',
              height: '270px',
              width: '397px',
              boxShadow: 'rgb(239 239 239) 0px 0px 9px -1px'
            }}
            src={props.image}
            alt="CycloMedia afbeelding"
            onClick={() => openPanel()}
          />
        )}
      </>
    )
  }

  const getVgeType = (Huisnummer: string | undefined | null, Adresaanduiding: string | undefined | null) => {
    if (Huisnummer == null && Adresaanduiding == null) {
      return 'Terrein'
    } else if (Huisnummer == null && Adresaanduiding !== null) {
      return 'Overig Gebouwd'
    } else {
      return 'Adresseerbaar'
    }
  }

  const displayEenheiddetailSoortenNaam = (code?: string) => {
    const entity = eenheidDetailSoorten.find(x => x.code === code)
    return entity?.naam
  }

  const listStyles: Partial<IDetailsListStyles> = {
    root: {
      fontSize: '14px',
      FontWeight: 600,
      marginTop: '-7px'
    },

    headerWrapper: {
      fontSize: '14px'
    },
    contentWrapper: {
      fontSize: '14px',
      FontWeight: 600,
      root: {
        fontSize: '14px',
        FontWeight: 600
      }
    },
    focusZone: {
      root: {
        fontSize: '14px',
        FontWeight: 600
      }
    }
  }

  const pandColumns: IColumn[] = [
    {
      key: getPropertyName<IPand>('identificatie'),
      name: 'Identificatie',
      fieldName: getPropertyName<IPand>('identificatie'),
      minWidth: 125,
      maxWidth: 125,
      onRender: (x: IPand) => <span style={{ fontSize: '14px' }}>{x.identificatie}</span>
    },
    {
      key: getPropertyName<IPand>('status'),
      name: 'Status',
      fieldName: getPropertyName<IPand>('status'),
      minWidth: 115,
      maxWidth: 115,
      onRender: (x: IPand) => <span style={{ fontSize: '14px' }}>{x.status}</span>
    },
    {
      key: getPropertyName<IPand>('oorspronkelijkBouwjaar'),
      name: 'Bouwjaar',
      fieldName: getPropertyName<IPand>('oorspronkelijkBouwjaar'),
      minWidth: 30,
      maxWidth: 30,
      onRender: (x: IPand) => <span style={{ fontSize: '14px' }}>{x.oorspronkelijkBouwjaar}</span>
    }
  ]

  const onRenderCompactMapsCard = (item: IVge): JSX.Element => {
    return (
      <div>
        {item?.lat ? (
          <iframe
            title="Map"
            width="100%"
            height="300"
            style={{ border: '0' }}
            src={`https://www.google.com/maps/embed/v1/place?q=${item?.lat},${item?.long}&key=AIzaSyCvaLeRfzfAtc7LJxbk5HhtcvB6WrKtX40&language=nl-NL`}
          />
        ) : (
          <div>Geen coordinaten</div>
        )}
      </div>
    )
  }

  const expandingCardProps: IExpandingCardProps = {
    onRenderCompactCard: onRenderCompactMapsCard,
    renderData: vge,
    expandedCardHeight: 0,
    compactCardHeight: 300
  }

  const tooltipId = useId('tooltip')

  const dismissAndDestroyStreetsmart = () => {
    if (streetsmartElement.current) {
      global.StreetSmartApi.destroy({ targetElement: streetsmartElement.current })
      dismissPanel()
    }
  }

  const handleItemInvoked = (id: any) => {
    navigate(PAGES[PageIdDefinition.vge.edit].route.replace(':id', id))
  }

  const createGelegenBinnenAddressText = (gelegenBinnenVgeId: number | undefined) => {
    const parentVge = items.find(x => x.id === gelegenBinnenVgeId)
    if (parentVge) return createAddressTextFromVge(parentVge)
    return '---'
  }

  const compareArrays = (a: string[] | undefined, b: string[] | undefined) => {
    if (a !== undefined && b !== undefined) return a.length === b.length && a.every((element, index) => element === b[index])
    return false
  }

  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false)
  const [isCalloutVisibleVerhuureenheid, { toggle: toggleIsCalloutVisibleVerhuureenheid }] = useBoolean(false)
  const buttonId = useId('callout-button')
  const labelId = useId('callout-label')
  const tooltipProps: ITooltipProps = {
    onRenderContent: () => (
      <>
        <div>Verhuureenheden:</div>
        <ul style={{ margin: 10, padding: 0 }}>
          {vge?.vhes?.map(vhe => (
            <li>{vhe}<br /></li>
          ))}
        </ul>
      </>
    )
  }

  const { data: hoofdDocumentUrl } = zodiosHooks.useGetApiDocumentshoofdDocumentForDoelTypeDoelTypedoelIdDoelId({
    params: { doelType: DocumentDoelType.vge, doelId: vge?.id! }
  }, { onError: (error) => setError(getTitleAndMessage(error).message), enabled: vge?.id !== undefined })

  const PhotoElement = () => {
    if (!hoofdDocumentUrl)
      return <div style={{
        height: '270px',
        width: '397px',
        padding: '10px',
        paddingLeft: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }} />
    if (hoofdDocumentUrl?.hoofdDocumentUrl) {
      return (
        <img
          style={{
            marginTop: '10px',
            height: '270px',
            width: '397px',
            boxShadow: 'rgb(239 239 239) 0px 0px 9px -1px',
            borderRadius: '8px'
          }}
          src={hoofdDocumentUrl?.hoofdDocumentUrl}
          alt="Hoofdfoto"
        />
      )
    } else if (vge?.lat && vge?.long) {
      return (tenant?.cyclomediaApiKey ? <CycloMediaPhoto isLoading={isLoading} image={image} /> :
        <GooglePhoto vge={vge} />)
    } else {
      return (
        <div
          style={{ width: '100%', padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Text>Er is geen hoofdfoto ingesteld om weer te geven</Text>
        </div>
      )
    }
  }

  const cardHeight = '350px'

  return (
    <div id="outerdiv" style={{ height: '80vh', position: 'relative' }}>
      <ErrorMessageBar error={error} />
      <Panel
        headerText={`Vastgoedeenheid op ${vge?.str} ${vge?.nr} ${vge?.wpl}`}
        onOpened={() => InitCyclomedia(`${vge?.str} ${vge?.nr} ${vge?.wpl}`)}
        isOpen={isOpen}
        onDismiss={dismissAndDestroyStreetsmart}
        closeButtonAriaLabel="Sluiten"
        type={PanelType.smallFluid}
      >
        <div ref={streetsmartElement} id="streetsmartApi" style={{ height: '90vh', width: '100%' }} />
      </Panel>
      <ScrollablePane styles={{ root: { height: '100%' } }} scrollbarVisibility={ScrollbarVisibility.auto}>
        <Stack id="outerStack" tokens={stackTokens} wrap horizontal
               styles={{ root: { overflowX: 'hidden', overflowY: 'hidden' } }}>
          <StackItem>
            <PhotoElement />
            {props.labelletter && (<div style={{ paddingTop: '12px' }}><EnergyLabel label={props.labelletter} /></div>)}
          </StackItem>
          <StackItem styles={{ root: { height: 'auto' } }}>
            <TooltipHost content={vge?.wpl ?? ''} id={tooltipId} calloutProps={calloutProps}
                         styles={hostStyles}>
              <InputCard title="Locatie" icon={locatie} height={cardHeight}>
                <TooltipHost content={vge?.wpl ?? ''} id={tooltipId} calloutProps={calloutProps}
                             styles={hostStyles}>
                  <AdresseerbarReadOnlyField title="Woonplaats" value={vge?.wpl} />
                </TooltipHost>
                <AdresseerbarReadOnlyField title="Postcode" value={vge?.pc} />
                <TooltipHost content={vge?.str ?? ''} id={tooltipId} calloutProps={calloutProps}
                             styles={hostStyles}>
                  <AdresseerbarReadOnlyField title="Straat" value={vge?.str} />
                </TooltipHost>
                <AdresseerbarReadOnlyField title="Huisnummer" value={vge?.nr} />
                <AdresseerbarReadOnlyField title="Huisletter" value={vge?.ltr} />
                <AdresseerbarReadOnlyField title="Huisnr. toev." value={vge?.nrT} />
                <AdresseerbarReadOnlyField title="Adresaanduiding" value={vge?.aa} />
                <AdresseerbarReadOnlyField title="Locatieaanduiding" value={vge?.loc} />
                <HoverCard expandingCardProps={expandingCardProps} instantOpenOnClick={true} styles={{}}>
                  <AdresseerbarReadOnlyField title="Coordinaten" value={vge?.lat && vge?.long ? `${vge?.lat},${vge?.long}` : ''} />
                </HoverCard>
              </InputCard>
            </TooltipHost>
          </StackItem>
          <StackItem styles={{ root: { height: 'auto' } }}>
            <InputCard title="Details" icon={details} height={cardHeight}>
              <AdresseerbarReadOnlyField title="Vastgoedeenheid" value={vge?.id} />
              <TooltipHost content={vge?.bn} id={tooltipId} calloutProps={calloutProps} styles={hostStyles}>
                <AdresseerbarReadOnlyField title="Beheerder" value={vge?.bn} />
              </TooltipHost>
              <AdresseerbarReadOnlyField title="VGE Type" value={getVgeType(vge?.nr, vge?.aa)} />
              <TooltipHost content={displayEenheiddetailSoortenNaam(vge?.srt)} id={tooltipId}
                           calloutProps={calloutProps} styles={hostStyles}>
                <AdresseerbarReadOnlyField title="Eenheiddetailsoort"
                                           value={displayEenheiddetailSoortenNaam(vge?.srt)} />
              </TooltipHost>
              <AdresseerbarReadOnlyField title={'Verhuurbaar'} value={(vge?.vhb)}></AdresseerbarReadOnlyField>
              {(vge?.redennvId != null) && (
                <AdresseerbarReadOnlyField title={'Reden niet verhuurd'} value={vge.redennv} />
              )}
              {getVgeType(vge?.nr, vge?.aa) === 'Terrein' ?
                <AdresseerbarReadOnlyField title="Terrein classificatie" value={vge?.trnNm} /> : null}
              <TooltipHost content={vge?.opm ?? ''} id={tooltipId} calloutProps={calloutProps} styles={hostStyles}>
                <AdresseerbarReadOnlyField title="Opmerking" value={vge?.opm} />
              </TooltipHost>
              <AdresseerbarReadOnlyField title="Advertentietekst" value={vge?.advt} />
              <AdresseerbarReadOnlyField title="Koopgarant" value={vge?.kpgrnt ? 'Ja' : 'Nee'} />
              <AdresseerbarReadOnlyField title="Etage" value={vge?.etg} />
              <AdresseerbarReadOnlyField title="Bouwnummer" value={vge?.bouwnummer} />
              <AdresseerbarReadOnlyField title="Is collectief" value={vge?.isCol ? 'Ja' : 'Nee'} />
              <AdresseerbarReadOnlyField title="Toegankelijkheid" value={vge?.toegankelijkheidNaam} />
              <AdresseerbarReadOnlyField title="(On)zelfstandig" value={vge?.zelfstandig} />
              <AdresseerbarReadOnlyField title="Reparatieverz. toestaan" value={vge?.repVerz ? 'Ja' : 'Nee'} />
              <AdresseerbarReadOnlyField title="Reparatieverz. beoord"
                                         value={vge?.repVerzBeoordelen ? 'Ja' : 'Nee'} />
              <AdresseerbarReadOnlyField title="Aantal naamplaatjes" value={vge?.aantalNaamplaatjes} />
            </InputCard>
          </StackItem>

          <StackItem styles={{ root: { height: 'auto' } }}>
            <InputCard title="Woningwaardering" icon={details} height={cardHeight}>
              <AdresseerbarReadOnlyField title="Bouwjaar" value={vge?.bouwjaar} />
              <AdresseerbarReadOnlyField title="Datum bouw gestart" value={vge?.dtbgstrt ?? 'Geen datum'} />
              <AdresseerbarReadOnlyField title="Datum ingebruikname" value={vge?.dtingbrkn ?? 'Geen datum'} />
              <AdresseerbarReadOnlyField title="Renovatiejaar" value={vge?.renovatiejaar} />
              <AdresseerbarReadOnlyField title="Renovatie Investering"
                                         value={vge?.renovatieInvestering?.toLocaleString('nl-NL')} />
              <AdresseerbarReadOnlyField title="Woonvz. gehandicapten"
                                         value={vge?.woonvoorzGehandicapten?.toLocaleString('nl-NL')} />
              <AdresseerbarReadOnlyField title="Levering warmte" value={vge?.warmteLeveringNaam} />
              <AdresseerbarReadOnlyField title="Zolder zonder vaste trap" value={vge?.zolderZonderVasteTrapNaam} />
              <AdresseerbarReadOnlyField title="Bad en aparte douche" value={vge?.isBadEnAparteDouche ? 'Ja' : 'Nee'} />
              <AdresseerbarReadOnlyField title="Taxatiewaarde" value={vge?.taxWrde} />
            </InputCard>
          </StackItem>
          <StackItem styles={{ root: { height: 'auto' } }}>
            <InputCard title="Gerelateerd aan" icon={gerelateerd} height={cardHeight}>
              <TooltipHost id={tooltipId} tooltipProps={tooltipProps} calloutProps={calloutProps}
                           styles={hostStyles} directionalHint={DirectionalHint.bottomRightEdge}>
                <AdresseerbarReadOnlyField title="Verhuureenheid" value={vge?.vhes?.join(', ')} />
              </TooltipHost>
              {items.filter(x => compareArrays(x.vhes, vge?.vhes)).length > 0 ? (
                <Stack horizontal>
                  <Stack.Item>
                    <Label
                      style={{
                        width: 180,
                        fontWeight: 'normal',
                        paddingLeft: '12px',
                        borderBottom: '1px solid rgb(219 219 219)'
                      }}
                    >
                      VGE's bij verhuureenheid
                    </Label>
                  </Stack.Item>
                  <Stack.Item style={{ width: 60 }}>
                    <DefaultButton
                      id={buttonId}
                      text="Tonen"
                      onClick={toggleIsCalloutVisibleVerhuureenheid}
                      style={{ width: 190, height: '29.5px', border: '1px solid rgb(219 219 219)' }}
                    />
                    {isCalloutVisibleVerhuureenheid && (
                      <Callout
                        coverTarget
                        ariaLabelledBy={labelId}
                        role="dialog"
                        className={styles.callout}
                        onDismiss={toggleIsCalloutVisibleVerhuureenheid}
                        target={`#${buttonId}`}
                        isBeakVisible={false}
                        setInitialFocus
                      >
                        <Stack>
                          <Label>VGE's bij verhuureenheid</Label>
                          <div style={{ height: '65vh', position: 'relative' }}>
                            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                              {items
                                .filter(x => compareArrays(x.vhes, vge?.vhes))
                                .map(childVge => (
                                  <Stack.Item key={childVge.id}>
                                    <Link onClick={() => handleItemInvoked(childVge.id.toString())}>
                                      {createAddressTextFromVge(childVge)} - {displayEenheiddetailSoortenNaam(childVge.srt)}
                                    </Link>
                                  </Stack.Item>
                                ))}
                            </ScrollablePane>
                          </div>
                        </Stack>
                      </Callout>
                    )}
                  </Stack.Item>
                </Stack>
              ) : (
                <AdresseerbarReadOnlyField title="VGE's bij verhuureenheid" value={'---'} />
              )}
              {vge?.gelegenBinnenVgeId ? (
                <TooltipHost
                  content={createGelegenBinnenAddressText(vge?.gelegenBinnenVgeId)}
                  id={tooltipId}
                  calloutProps={calloutProps}
                  styles={hostStyles}
                >
                  <Link key={vge?.id} onClick={() => handleItemInvoked(vge?.gelegenBinnenVgeId?.toString())}>
                    <AdresseerbarReadOnlyField title="Gelegen binnen"
                                               value={createGelegenBinnenAddressText(vge?.gelegenBinnenVgeId)} />
                  </Link>
                </TooltipHost>
              ) : (
                <AdresseerbarReadOnlyField title="Gelegen binnen" value={'---'} />
              )}
              {items.filter(x => x.gelegenBinnenVgeId === vge?.id).length > 0 ? (
                <Stack horizontal>
                  <Stack.Item>
                    <Label
                      style={{
                        width: 180,
                        fontWeight: 'normal',
                        paddingLeft: '12px',
                        borderBottom: '1px solid rgb(219 219 219)'
                      }}
                    >
                      Onderliggende VGE
                    </Label>
                  </Stack.Item>
                  <Stack.Item style={{ width: 60 }}>
                    <DefaultButton
                      id={buttonId}
                      text="Tonen"
                      onClick={toggleIsCalloutVisible}
                      style={{ width: 190, height: '29.5px', border: '1px solid rgb(219 219 219)' }}
                    />
                    {isCalloutVisible && (
                      <Callout
                        coverTarget
                        ariaLabelledBy={labelId}
                        role="dialog"
                        className={styles.callout}
                        onDismiss={toggleIsCalloutVisible}
                        target={`#${buttonId}`}
                        isBeakVisible={false}
                        setInitialFocus
                      >
                        <Stack>
                          <Label>Onderliggende VGEs</Label>
                          <div style={{ height: '65vh', position: 'relative' }}>
                            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                              {items
                                .filter(x => x.gelegenBinnenVgeId === vge?.id)
                                .map(childVge => (
                                  <Stack.Item key={childVge.id}>
                                    <Link onClick={() => handleItemInvoked(childVge.id.toString())}>
                                      {createAddressTextFromVge(childVge)} - {displayEenheiddetailSoortenNaam(childVge.srt)}
                                    </Link>
                                  </Stack.Item>
                                ))}
                            </ScrollablePane>
                          </div>
                        </Stack>
                      </Callout>
                    )}
                  </Stack.Item>
                </Stack>
              ) : (
                <AdresseerbarReadOnlyField title="Onderliggende VGE" value={'---'} />
              )}
              {vge?.gebouwenClustersCode ? (
                <TooltipHost
                  content={vge?.gebouwenClustersName}
                  id={tooltipId}
                  calloutProps={calloutProps}
                  styles={hostStyles}
                >
                  <LinkReadOnlyField
                    title="Gebouwencluster"
                    value={vge?.gebouwenClustersName}
                    url={availableUrls.GebouwenClusterEdit.replace(':id', vge?.gebouwenClusterId?.toString()!)}
                    labelWidth={167}
                  />
                </TooltipHost>
              ) : (
                <AdresseerbarReadOnlyField title="Gebouwencluster" value={'---'} />
              )}
              <TooltipHost content={vge?.gebN} id={tooltipId} calloutProps={calloutProps} styles={hostStyles}>
                <LinkReadOnlyField
                  title="Gebouw"
                  value={vge?.gebN}
                  url={availableUrls.GebouwEdit.replace(':id', vge?.geb!)}
                  labelWidth={167}
                />
              </TooltipHost>
              {vge?.gdl ? (
                <TooltipHost content={vge?.gdl === 0 ? '' : vge?.gdlN} id={tooltipId} calloutProps={calloutProps}
                             styles={hostStyles}>
                  <LinkReadOnlyField
                    title="Gebouwdeel"
                    value={vge?.gdl === 0 ? '' : vge?.gdlN}
                    url={availableUrls.Gebouwdeel.replace(':id', vge?.gdl?.toString()!)}
                    labelWidth={167}
                  />
                </TooltipHost>
              ) : (
                <AdresseerbarReadOnlyField title="Gebouwdeel" value={'---'} />
              )}
              <AdresseerbarReadOnlyField title="Kadastrale aand. object" value={vge?.kad} />
              <AdresseerbarReadOnlyField title="Kadastrale aand. vlak" value={vge?.kadV ?? vge?.kad} />
              <TooltipHost content={vge?.vveNaam} id={tooltipId} calloutProps={calloutProps} styles={hostStyles}>
                <LinkReadOnlyField
                  title="VvE"
                  value={vge?.vveNaam}
                  url={vge?.vveId ? PAGES[PageIdDefinition.beheer.relatie.vves.edit].route.replace(':id', vge?.vveId?.toString()) : ''}
                  labelWidth={167}
                />
              </TooltipHost>
              <AdresseerbarReadOnlyField title="Vaste-activanummer" value={vge?.vasAnr} />
              <AdresseerbarReadOnlyField title="Bestemming" value={vge?.bst} />
              {vge?.isCol === true &&
                <AdresseerbarReadOnlyField title="Externe coll. referentie" value={vge?.ref} />
              }
              <HuurcontractbepalingenTooltip huurcontractbepalingen={vge?.huurcontractbepalingen}
                                             labelWidth={171} />
              {tenant?.heeftZakelijkeVerhuur === true &&
                <AdresseerbarReadOnlyField title="Taak type" value={vge?.taakTypeOptieNaam} />
              }

            </InputCard>
          </StackItem>

          <StackItem styles={{ root: { height: 'auto' } }}>
            <InputCard title="CBS" icon={cbs} height={cardHeight}>
              <TooltipHost content={vge?.gemeente} id={tooltipId} calloutProps={calloutProps} styles={hostStyles}>
                <AdresseerbarReadOnlyField title="Gemeente" value={vge?.gemeente} />
              </TooltipHost>
              <TooltipHost content={vge?.wk} id={tooltipId} calloutProps={calloutProps} styles={hostStyles}>
                <AdresseerbarReadOnlyField title="Wijk" value={vge?.wk} />
              </TooltipHost>
              <TooltipHost content={vge?.brt} id={tooltipId} calloutProps={calloutProps} styles={hostStyles}>
                <AdresseerbarReadOnlyField title="Buurt" value={vge?.brt} />
              </TooltipHost>
            </InputCard>
          </StackItem>

          <StackItem styles={{ root: { height: 'auto' } }}>
            <InputCard title="Adresseerbaar object" icon={adresseerbaarObjectIcon} height={cardHeight}>
              <AdresseerbarReadOnlyField title="Identificatie" value={vge?.baGid} />
              <AdresReadOnlyField title="Indentificatietype" value={bagObject.type} isLoaded={!isAdresLoading} />
              {bagObject?.gebruiksdoelen && bagObject?.gebruiksdoelen.length > 25 ? (
                <TooltipHost content={bagObject?.gebruiksdoelen} id={gebruiksdoelTooltipId}>
                  <AdresReadOnlyField title="Gebruiksdoel" value={bagObject.gebruiksdoelen}
                                      isLoaded={!isAdresLoading} />
                </TooltipHost>
              ) : (
                <TooltipHost content={bagObject?.gebruiksdoelen} id={tooltipId} calloutProps={calloutProps}
                             styles={hostStyles}>
                  <AdresReadOnlyField title="Gebruiksdoel" value={bagObject.gebruiksdoelen}
                                      isLoaded={!isAdresLoading} />
                </TooltipHost>
              )}
              <AdresReadOnlyField title="Status" value={bagObject.status} isLoaded={!isAdresLoading} />
              <AdresReadOnlyField title="Gebruiksoppervlakte" value={bagObject.oppervlakte}
                                  isLoaded={!isAdresLoading} />
              <AdresReadOnlyField title="Documentdatum" value={bagObject.documentdatum} isLoaded={!isAdresLoading} />
            </InputCard>
          </StackItem>

          <StackItem styles={{ root: { height: 'auto' } }}>
            <InputCard title="Pand(en)" icon={panden} height={cardHeight}>
              <DetailsList columns={pandColumns} selectionMode={0} styles={listStyles}
                           items={bagObject.maaktDeelUitVan ?? pandData} />
            </InputCard>
          </StackItem>

          <StackItem styles={{ root: { height: 'auto' } }}>
            <InputCard title="Monumenten" icon={monumenten} height={cardHeight}>
              <AdresseerbarReadOnlyField title="Rijks" value={vge?.rijksMnmnt ? 'Ja' : 'Nee'} />
              <AdresseerbarReadOnlyField title="Provinciaal" value={vge?.prvnclMnmnt ? 'Ja' : 'Nee'} />
              <AdresseerbarReadOnlyField title="Gemeentelijk" value={vge?.gmntljkMnmnt ? 'Ja' : 'Nee'} />
              <AdresseerbarReadOnlyField title="Stads- of dorpsgezicht"
                                         value={vge?.bschrmdStdsOfdrpgzcht ? 'Ja' : 'Nee'} />
            </InputCard>
          </StackItem>

          <StackItem styles={{ root: { height: 'auto' } }}>
            <InputCard title="Nevenadres(sen)" width="816px" height={cardHeight}>
              <DetailsList
                columns={[
                  createColumn('Identificatie', getPropertyName<INummeraanduiding>('identificatie')),
                  createColumn('Status', getPropertyName<INummeraanduiding>('status')),
                  createColumn('Postcode', getPropertyName<INummeraanduiding>('postcode')),
                  createColumn('Huisnummer', getPropertyName<INummeraanduiding>('huisnummer')),
                  createColumn('Huisletter', getPropertyName<INummeraanduiding>('huisletter')),
                  createColumn('Toev.', getPropertyName<INummeraanduiding>('huisnummertoevoeging'))
                ]}
                selectionMode={0}
                styles={listStyles}
                items={bagObject.heeftAlsNevenAdres ? bagObject.heeftAlsNevenAdres : []}
              />
            </InputCard>
          </StackItem>
        </Stack>
      </ScrollablePane>
    </div>
  )
}

export default AdresseerbaarObject
