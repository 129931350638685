import React from 'react'
import { FontIcon } from '@fluentui/react'
import { NeutralColors, SharedColors } from '@fluentui/theme';

interface IIconProps {
  name: string
  variant?: 'light' | 'dark' | 'green' | 'red'
  className?: string
}

const Icon: React.FC<IIconProps> = ({ name, variant = 'dark', className }) => {
  const color = () =>{
    switch(variant){
      case 'light': return NeutralColors.gray80;
      case 'dark': return NeutralColors.gray160;
      case 'green': return SharedColors.green10;
      case 'red': return SharedColors.red20;
      default: return NeutralColors.gray40;
    }
  }
  return (
    <div className={`${className}`} style={{color: color(), fontSize: '28px'}}>
      <FontIcon iconName={name} />
    </div>
  )
}

export default Icon