import { useEffect, useRef, useState } from 'react'
import { Persona, PersonaSize, Image, Text, Panel, } from '@fluentui/react'
import {
    ActivityItem, mergeStyleSets,
    Link as FluentLink, Icon, IconButton, IIconProps
} from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks';
import { useNavigate, Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getIsInitialized } from 'store/selectors/app'
import ProfileCallout from './ProfileCallout'
import { getGraphToken } from 'lib/auth'
import { useIsAuthenticated } from '@azure/msal-react'
import { fetchCurrentTenant } from 'store/actions/app/tenantSlice'
import { initializeTestData, INotification, countNotShownNotifications, selectNotificationsForPanel, updateNotificationShown, selectNotificationsToToast, updateToastHandled, dismissNotification } from '../../features/vge/notificaties/notificatiesSlice'
import { getSelectedEntity as getSelectedTenant } from 'store/actions/app/tenantSlice'
import Notifications from './icons/notifications.svg'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './index.css'
import { uniqueId } from 'lodash';
import IGraphUserProfile from "../../interfaces/graphUserProfile";
import { useAppDispatch } from "../../store";
import { fetchAll as fetchAllVges, selectAll } from "../../features/vge/vgeSlice";
import {
    fetchGebouwenClusters,
    selectAll as selectAllGebouwenClusters
} from "../../features/beheer/fysiek/gebouwenclusters/gebouwenClustersSlice";
import { fetchUserRoles } from "../../store/actions/app/rolesSlice";

const classNames = mergeStyleSets({
    exampleRoot: {
        marginTop: '20px',
    },
    nameText: {
        fontWeight: 'bold',
    },
    dismissButton: {
        position: 'relative',
        left: '265px',
        bottom: '37px',
        color: 'grey',
    },
    ActivityItemContainer: {
        borderTop: '1px solid grey',
    },
    toast: {
        top: '500'
    }
});

const Toolbar = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const isAuthenticated = useIsAuthenticated()

    const [imageUrl, setImageUrl] = useState('')
    const [profileCalloutVisible, setProfileCalloutVisible] = useState(false)

    const isInitialized = useSelector(getIsInitialized)

    const personaRef = useRef<HTMLDivElement>(null)
    const ready = isAuthenticated && isInitialized

    const currentTenant = useSelector(getSelectedTenant)

    const notificatiesToDisplay: INotification[] = useSelector(selectNotificationsForPanel)
    const numberForBellIcon = useSelector(countNotShownNotifications)
    const toastNotifications = useSelector(selectNotificationsToToast);

    const vgeSelector = useSelector(selectAll)
    const gebouwenClustersSelector = useSelector(selectAllGebouwenClusters)
    useEffect(() => {
        if (isAuthenticated) {
            dispatch(fetchUserRoles())
            if (vgeSelector.length === 0) dispatch(fetchAllVges())
            if (gebouwenClustersSelector.length === 0) dispatch(fetchGebouwenClusters({filter: ''}))
        }
    }, [isAuthenticated])
    useEffect(() => {
        dispatch(initializeTestData())
    }, [])

    useEffect(() => {
        toastNotifications.forEach(element => {
            toast.info(element.description + " voor " + element.vgeAddress, {
                className: classNames.toast,
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                onClick: () => {
                    navigate(element.actionUrl)
                }
            })
            dispatch(updateToastHandled(element))
        })
    }, [toastNotifications])

    const fetchImage = async () => {
        try {
            const token = await getGraphToken()
            const resp = await fetch('https://graph.microsoft.com/v1.0/me/photos/48x48/$value', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })

            const url = URL.createObjectURL(await resp.blob())
            setImageUrl(url)
        } catch (error) { console.log('load error icon:' + error) }
    }

    const fetchMe = async () => {
        try {
            const token = await getGraphToken()
            const resp = await fetch('https://graph.microsoft.com/v1.0/me', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })

            const json = await resp.json()
            const user: IGraphUserProfile = {
                displayName: json.displayName,
                mail: json.mail,
                givenName: json.givenName,
                jobTitle: json.jobTitle,
                id: json.id,
            }
            setProfile(user)
        } catch (error) { console.log('load error me: ' + error) }
    }
    const fetchTenant = async () => {
        try {
            dispatch(fetchCurrentTenant())
        } catch (error) { console.log('load error tenant: ' + error) }
    }

    const [profile, setProfile] = useState<IGraphUserProfile>()

    useEffect(() => {
        if (ready) {
            fetchMe()
            fetchImage()
            fetchTenant()

            return () => {
                if (imageUrl) {
                    URL.revokeObjectURL(imageUrl)
                }
            }
        }
    }, [ready])

    const handleHomePageClick = () => {
        navigate('/')
    }

    const cancelIcon: IIconProps = { iconName: 'Cancel', styles: { root: { fontSize: '10px' } } };

    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);

    const onBellClick = () => {
        openPanel()

        const payload = notificatiesToDisplay.map(notificatie => { return { ...notificatie, notificationShown: true } })
        dispatch(updateNotificationShown(payload))
    }

    return (
        <div className="toolbar">
            <ToastContainer style={{ top: 50 }} autoClose={5000} />
            <div key={uniqueId()} className="toolbarContainer">
                <div className="toolbarLeft">
                    <div className="toolbarItem toolbarText" onClick={handleHomePageClick}>
                        <Image src={`${process.env.PUBLIC_URL}/cropped-Vastgoed-icon-32x32.png`} height={48} />
                        <Text style={{ color: 'white' }} variant={'xLarge'}>Vastgoed</Text>
                        <Text style={{ color: 'white' }} variant={'xLarge'}>tabel.nl</Text>
                    </div>
                </div>
                <div key={uniqueId()} className="toolbarCenter"></div>
                <div key={uniqueId()} className="toolbarRight">
                    <div key={uniqueId()} className="toolbarItem" style={{ color: 'white', fontSize: 'larger', fontWeight: 'bolder', marginRight: 10 }}>
                        {currentTenant && <label>{currentTenant?.omgeving ? currentTenant?.omgeving.toUpperCase() : ''}</label>}
                    </div>
                    <div className="toolbarItem" onClick={onBellClick}>
                        <img src={Notifications} alt='Notificaties' />
                        <span style={{
                            backgroundColor: '#424140',
                            color: 'white',
                            fontSize: '9px',
                            fontWeight: 'normal',
                            borderRadius: '45%',
                            textAlign: 'center',
                            padding: '1px 3px 2px 3px',
                            position: 'relative',
                            bottom: '5.5px',
                            right: '12.1px',
                            width: '12px'
                        }}>{numberForBellIcon < 10 ? numberForBellIcon : <span>9+</span>}
            </span>
                        <Panel
                            key={uniqueId()}
                            headerText="Notificaties"
                            isLightDismiss
                            isOpen={isOpen}
                            onDismiss={dismissPanel}
                            closeButtonAriaLabel="Close">
                            {notificatiesToDisplay.length > 0 ?
                                notificatiesToDisplay.map(n => {
                                    return (
                                        <div key={uniqueId()} className={classNames.ActivityItemContainer}>
                                            <Link to={n.actionUrl} onClick={dismissPanel}>
                                                <ActivityItem key={uniqueId()}
                                                              activityDescription={[<FluentLink className={classNames.nameText}>{n.description}</FluentLink>]}
                                                              comments={[<span key={uniqueId()}>{n.vgeAddress}</span>, <div />, <span>Uitgevoerd door:</span>, <FluentLink className={classNames.nameText}>@{n.initiatedBy}</FluentLink>]}
                                                              timeStamp={n.finished.slice(11, 16)} activityIcon={<Icon iconName={'Message'} />}
                                                              className={classNames.exampleRoot} />
                                            </Link>
                                            <IconButton iconProps={cancelIcon} ariaLabel="Dismiss" className={classNames.dismissButton} onClick={() => dispatch(dismissNotification(n))} />
                                        </div>)
                                })
                                : <p>Geen notificaties om te tonen voor de huidige sessie.</p>}
                            {notificatiesToDisplay.length > 0 ? <div style={{ borderTop: '1px solid grey' }}></div> : null}
                        </Panel>
                    </div>
                    <div key={uniqueId()} className="toolbarItem" style={{ color: 'white', fontSize: 'larger', fontWeight: 'bolder', marginRight: 10 }}>
                        {currentTenant && <label>{currentTenant?.name}</label>}
                    </div>
                    <div key={uniqueId()} className="toolbarItem toolbarIcon" ref={personaRef}>
                        <Persona
                            size={PersonaSize.size40}
                            imageInitials={"name"}
                            imageUrl={imageUrl}
                            onClick={() => setProfileCalloutVisible(prev => !prev)}
                            styles={{ root: { width: 40 } }}
                        />
                    </div>
                    <ProfileCallout visible={profileCalloutVisible} onDismiss={() => setProfileCalloutVisible(false)} targetRef={personaRef} profile={profile} />
                </div>
            </div>
        </div>
    )
}

export default Toolbar
