import { createAsyncThunk, createEntityAdapter, createSlice, EntityId, PayloadAction } from '@reduxjs/toolkit'
import { PagedEntities } from 'interfaces/pagedEntities'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'
import { setPendingState, setRejectedState, setSucceededState } from 'lib/sliceSupport'
import { IVge } from 'features/vge/vgeSlice'

interface IVgeContractState {
  selectedVge: IVge | undefined
  selectedId: number | undefined
  status: 'idle' | 'pending' | 'succeeded' | 'failed'
  error: string | null
}

interface IVgeContractFetchArgs {
  gebouwIds: number[]
  gebouwdeelIds: number[]
  filter?: string
}

const vgeContractInitialState: IVgeContractState = {
  selectedVge: undefined,
  selectedId: undefined,
  status: 'idle',
  error: null,
}

const entityAdapter = createEntityAdapter<IVge>()

const baseUrl = '/api/OnderhoudsContract/vges'
const basePrefix = 'onderhoudsContract/vges'
const getSliceState = (state: RootState) => state.onderhoudsContractVges

export const fetchAll = createAsyncThunk(`${basePrefix}/fetchAll`, async (args: IVgeContractFetchArgs, thunkAPI) => {
  let uri: string
  if (args.filter) uri = `${baseUrl}?top=50000&filter=${args.filter}`
  else uri = `${baseUrl}?top=50000`
  return await invokeFetch<PagedEntities<IVge>>(thunkAPI, 'POST', uri, args)
})

export const vgeContractSlice = createSlice({
  name: basePrefix,
  initialState: entityAdapter.getInitialState(vgeContractInitialState),

  reducers: {
    clearError: state => {
      state.error = null
    },
    select: (state, action: PayloadAction<EntityId | undefined>) => {
      state.selectedId = action.payload as number | undefined
    },
    clearSelection: state => {
      state.selectedId = undefined
    },
    add: entityAdapter.addOne,
    modify: entityAdapter.upsertOne,
    removeMany: entityAdapter.removeMany,
    setAll: entityAdapter.setAll,
    setSelectedVge: (state, action: PayloadAction<number>) => {
      state.selectedId = action.payload
      const vge = state.entities[action.payload]
      if (vge) state.selectedVge = vge
    },
    clearResults: state => {
      entityAdapter.removeAll(state)
    },
  },

  extraReducers: builder => {
    builder.addCase(fetchAll.pending, state => setPendingState(state))
    builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<PagedEntities<IVge>>) => {
      setSucceededState(state)
      entityAdapter.setAll(state, action.payload.items)
    })
    builder.addCase(fetchAll.rejected, (state, action) => setRejectedState(state, action))
  },
})

export const getSelectedEntity = (state: RootState) => {
  const selectedId = getSliceState(state).selectedId
  if (selectedId) return getSliceState(state).entities[selectedId]
  else return undefined
}

export const getLoadingState = (state: RootState) => {
  return getSliceState(state).status
}

export const getErrorState = (state: RootState) => {
  return getSliceState(state).error
}

export const { add, modify, removeMany, select, clearSelection, setSelectedVge, clearResults } = vgeContractSlice.actions
export const { selectAll, selectEntities, selectById } = entityAdapter.getSelectors<RootState>(state => state.onderhoudsContractVges)

export default vgeContractSlice.reducer
