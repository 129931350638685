import { useNavigate } from 'react-router-dom'
import { IColumn, Link } from '@fluentui/react'
import { getPropertyName } from 'lib/interfaceUtils'
import React, { useState } from 'react'
import { transformDateString } from '../lemdo/common/support'
import { PageIdDefinition, PAGES } from 'pages'
import { usePagedParams } from '../../../services/usePagedParams'
import { OnderhoudscontractDto, zodiosHooks } from '../../../api/ApiClient'
import { VgtPagedTable } from '../../../components/VgtPagedTable/VgtPagedTable'
import { z } from 'zod'
import { VgtSearch } from '../../../components/VgeSearch/VgtSearch'
import DeleteConfirmationDialog from "../../../components/DeleteConfirmationDialog";
import { getTitleAndMessage } from "../../../services/HandleError";
import { IOnderhoudsContract } from './onderhoudsContractSlice'
import Icon from '../../../components/Icon'
import { createColumn } from 'lib/gridHelper'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'

type OnderhoudscontractDtoType = z.infer<typeof OnderhoudscontractDto>;

const onGetKey = (item: OnderhoudscontractDtoType) => item?.id

const defaultOnderhoudsContract = {
    id: 0,
    leverancierId: 0,
    voorVolledigBezit: false
} as OnderhoudscontractDtoType

const OnderhoudsContractGrid: React.FC<{}> = () => {
    const navigate = useNavigate()
    const [selectedItems, setSelectedItems] = useState<number[]>([] as number[])
    const {validatedSearchParams, setPage, setOrder, setFilter} = usePagedParams()
    const [showDialog, setShowDialog] = useState(false)
    const [deleteWarningMessage, setDeleteWarningMessage] = useState('')
    const [error, setError] = useState<string>()

    const {
        data,
        invalidate: fetchOnderhoudsContract,
        isLoading: isFetchingOnderhoudsContract
    } = zodiosHooks.useGetApiOnderhoudscontract({
        queries: {
            SortKey: validatedSearchParams.sortKey,
            SortDirection: validatedSearchParams.sortDirection,
            PageIndex: validatedSearchParams.page,
            PageSize: validatedSearchParams.pageSize,
            Filter: validatedSearchParams.filter
        }
    })
    
    const {data: suppliersResponse } = zodiosHooks.useGetRechtspersonen({
        queries: {
            Filter: '',
            RelatieRol: 'Leverancier'
        }
    })

    const {mutate: deleteOnderhoudsContract, isLoading: isDeleting} = zodiosHooks.useDeleteApiOnderhoudscontract({},
    {
        onSuccess: () => fetchOnderhoudsContract(),
        onError: (error) => setError(getTitleAndMessage(error).message)
    })

    const handleListSelectionChanged = (ids: number[]) => {
        setSelectedItems(ids)
    }

    const handleItemInvoked = (item: OnderhoudscontractDtoType) => {
        navigate(PAGES[PageIdDefinition.contract.edit].route.replace(':id', item.id.toString()))
    }
    
    const removeItems = () => {
        if(isDeleting) return;
        
        const itemsToDelete = data?.items?.filter(i => selectedItems.includes(i.id));
        if (itemsToDelete) {
            let warningMessage = 'Aan deze onderhoudscontracten zijn gebouwen en/of taken gekoppeld:'
            for (const onderhoudscontract of itemsToDelete) {
                if (onderhoudscontract.taken?.length ?? -1 > 0) {
                    warningMessage += `\n- ${onderhoudscontract.contractNummer}`
                    setShowDialog(true)
                    setDeleteWarningMessage(warningMessage)
                    return
                }
            }
            removeConfirm()
        }
    }

    const removeConfirm = () => {
        deleteOnderhoudsContract(selectedItems)
        fetchOnderhoudsContract()
        setShowDialog(false)
    }

    const getRelatieHandelsNaam = (relatieId: number) => {
        return suppliersResponse?.items ? suppliersResponse.items.find(i => i.id === relatieId)?.handelsnaam ?? '' : ''
    }

    const  getIntakeDoorCorporatie =   (relatieId: number) => {
        return suppliersResponse?.items ? suppliersResponse.items.find(i => i.id === relatieId)?.intakeDoorCorporatie ?? false : false
    }

    const columns: IColumn[] = React.useMemo(() => {
        return [
          createColumn('Contractnummer', getPropertyName<OnderhoudscontractDtoType>('contractNummer'), 'XL', true,
            (item: OnderhoudscontractDtoType) => (
                <Link key={item.id} onClick={() => handleItemInvoked(item)}>
                    {item.contractNummer}
                </Link>
            )),
          createColumn('Contractomschrijving', getPropertyName<OnderhoudscontractDtoType>('contractOmschrijving'), 'XL', true),
          createColumn('Contractbeheerder', getPropertyName<OnderhoudscontractDtoType>('contractBeheerder'), 'XL', true),
          createColumn('Contractpartij', getPropertyName<OnderhoudscontractDtoType>('leverancierId'), 'XL', true, (item: OnderhoudscontractDtoType) => <label>{getRelatieHandelsNaam(item.leverancierId)}</label>),
          createColumn('Intake door corporatie', getPropertyName<OnderhoudscontractDtoType>('intakeDoorCorporatie'), 'XL', true, (item: IOnderhoudsContract) => { return getIntakeDoorCorporatie(item.leverancierId) ? <Icon name="Accept" variant="red" /> : <Icon name="Cancel" variant="light" />}),
          createColumn('Ingangsdatum', getPropertyName<OnderhoudscontractDtoType>('ingangsDatum'), 'M', true, (item: OnderhoudscontractDtoType) => <>{transformDateString(item.ingangsDatum)}</>),
          createColumn('Einddatum', getPropertyName<OnderhoudscontractDtoType>('eindDatum'), 'M', true, (item: OnderhoudscontractDtoType) => <>{transformDateString(item.eindDatum)}</>)
        ]
      }, [])

      const commandItems = [
        { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
        { text: 'Verversen', onClick: () => () => fetchOnderhoudsContract(), icon: 'Refresh', iconOnly: true },
        { text: 'Nieuw', onClick: () => handleItemInvoked(defaultOnderhoudsContract), icon: 'NewFolder', iconOnly: true },
        { text: 'Verwijderen', onClick: () => removeItems(), icon: 'Delete', iconOnly: true, disabled: selectedItems.length === 0 }
      ]

    return (
        <MainTemplate title='Onderhoudscontracten' subTitle='Overzicht' commandItems={commandItems} error={error}>
            <DeleteConfirmationDialog
                onConfirm={removeConfirm}
                onCancel={() => {
                    setShowDialog(false)
                    setDeleteWarningMessage('')
                }}
                entityName={'Onderhoudscontracten'}
                showDialog={showDialog}
                customMessage={deleteWarningMessage}
            />
            <VgtSearch onSearch={setFilter} filter={validatedSearchParams.filter}/>
            <VgtPagedTable
                items={data?.items ?? undefined}
                height={'203'}
                isLoading={isFetchingOnderhoudsContract}
                columns={columns}
                pagingInfo={{
                    pageSize: validatedSearchParams.pageSize,
                    currentPage: validatedSearchParams.page,
                    totalCount: data?.totalCount ?? 0,
                    totalPages: data?.totalPages ?? 0,
                    hasNextPage: data?.hasNextPage ?? false,
                    hasPreviousPage: data?.hasPreviousPage ?? false,
                    sortKey: validatedSearchParams.sortKey || 'contractNummer',
                    sortDirection: validatedSearchParams.sortDirection || 'asc'
                }}
                getKey={onGetKey}
                onPageChanged={setPage}
                onSortChanged={setOrder}
                onItemInvoked={handleItemInvoked}
                onSelectionChanged={handleListSelectionChanged}
            />
        </MainTemplate>
    )
}

export default OnderhoudsContractGrid
