import {
  DefaultButton,
  IButtonStyles,
  IColumn,
  IconButton,
  IDetailsHeaderStyles,
  Link,
  mergeStyleSets,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Text
} from '@fluentui/react'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DeleteWarningDialog from './deleteWarningDialog'
import {
  GebouwenClusterListDto,
  GerelateerdGebouwDeelListDto,
  GerelateerdGebouwListDto,
  zodiosHooks
} from '../../../../api/ApiClient'
import { getTitleAndMessage } from '../../../../services/HandleError'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { usePagedParams } from '../../../../services/usePagedParams'
import { GebouwenClusterEditPanel } from './editGebouwenClusterPanel'
import { useBoolean } from '@fluentui/react-hooks'
import { VgtSearch } from '../../../../components/VgeSearch/VgtSearch'
import { z } from 'zod'
import { createCustomRenderedColumn } from '../../../../lib/gridHelper'
import { getPropertyName } from '../../../../lib/interfaceUtils'
import { availableUrls } from '../../../../routing/AvailableUrls'
import {
  DetailsHeader,
  DetailsList,
  DetailsRow,
  IDetailsListProps,
  IDetailsRowStyles
} from '@fluentui/react/lib/DetailsList'

const classNames = mergeStyleSets({
  mainContainerStyles: {
    height: '70vh',
    overflow: 'auto',
    '@media (min-height: 1080px)': {
      height: '80vh'
    }
  }
})

const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    width: 30,
    height: 'auto'
  },
  descriptionHovered: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}

type GebouwenClusterDtoType = z.infer<typeof GebouwenClusterListDto>;
type GebouwDtoType = z.infer<typeof GerelateerdGebouwListDto>;
type GebouwDelenDtoType = z.infer<typeof GerelateerdGebouwDeelListDto>;

const GebouwenClustersList: React.FC<{}> = _props => {
  const navigate = useNavigate()
  const [selectedItems, setSelectedItems] = useState<number[]>([])
  const [error, setError] = useState<string>()
  const { validatedSearchParams, setFilter, setPage, setOrder } = usePagedParams()
  const [inEditMode, { setTrue: openEditPanel, setFalse: dismissEditPanel }] = useBoolean(false)
  const [expandedRows, setExpandedRows] = useState<{ [key: number]: boolean }>({})

  const toggleRow = (id: number) => {
    setExpandedRows(prev => ({ ...prev, [id]: !prev[id] }))
  }

  const {
    data: gebouwenClusters,
    invalidate: fetchGebouwenClusters,
    isLoading: isFetchingGebouwenClusters
  } = zodiosHooks.useGetGebouwenClustersdetails({
    queries: {
      Filter: validatedSearchParams.filter,
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize
    }
  })

  const [hideDialog, setHideDialog] = useState<boolean>(true)

  const hasGebouwenLinkedToSelectedClusters = () => {
    return selectedItems.some(clusterId => {
      const cluster = gebouwenClusters?.items?.find(item => item.gebouwClusterId === clusterId)
      return cluster?.gerelateerdGebouwListDto && cluster.gerelateerdGebouwListDto.length > 0
    })
  }

  const { mutate: deleteGebouwenClusters } = zodiosHooks.useDeleteGebouwenClusters({}, {
    onSuccess: () => fetchGebouwenClusters(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const removeItems = () => {
    if (hasGebouwenLinkedToSelectedClusters()) {
      setHideDialog(false)
    } else {
      deleteGebouwenClusters(selectedItems)
      setSelectedItems([])
    }
  }

  const commandItems = useMemo(() => [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => fetchGebouwenClusters(), icon: 'Refresh' },
    {
      text: 'Toevoegen', onClick: () => {
        openEditPanel()
      }, icon: 'Add'
    },
    { text: 'Verwijderen', onClick: () => removeItems(), icon: 'Delete', disabled: !selectedItems.length }
  ], [selectedItems.length])

  const onGebouwenClusterClick = (item: GebouwenClusterDtoType) => {
    navigate(availableUrls.GebouwenClusterEdit.replace(':id', item.gebouwClusterId.toString()))
  }

  const onGebouwenClick = (item: GebouwDtoType) => {
    navigate(availableUrls.GebouwEdit.replace(':id', item.gebouwId.toString()))
  }

  const onGebouwDelenClick = (item: GebouwDelenDtoType) => {
    navigate(availableUrls.Gebouwdeel.replace(':id', item.gebouwDeelId.toString()))
  }

  const [selection] = useState(
    new Selection({
      onSelectionChanged: () => selectionChangedHandler()
    })
  )

  const selectionChangedHandler = () => {
    if (setSelectedItems) {
      const selectedItems = selection.getSelection() as GebouwenClusterDtoType[]
      const selectedIds = selectedItems.map(item => item.gebouwClusterId)
      setSelectedItems(selectedIds)
    }
  }

  const setSortingOnColumns = (columns: IColumn[]) => {

    return columns.map(column => {
      if (column.fieldName === validatedSearchParams.sortKey) {
        column.isSorted = true
        column.isSortedDescending = validatedSearchParams.sortDirection === 'desc'
      } else {
        column.isSorted = false
        column.isSortedDescending = false
      }
      return column
    })
  }

  const columns = [
    createCustomRenderedColumn(
      '',
      getPropertyName<GebouwenClusterDtoType>('gebouwClusterId'),
      (item: GebouwenClusterDtoType) => {
        const hasChildrenData = item.gerelateerdGebouwListDto && item.gerelateerdGebouwListDto?.length > 0
        return hasChildrenData ? (
          <IconButton
            iconProps={{ iconName: expandedRows[item.gebouwClusterId] ? 'Remove' : 'Add' }}
            onClick={() => toggleRow(item.gebouwClusterId)}
            styles={iconButtonStyles}
          />
        ) : null
      },
      30
    ),
    createCustomRenderedColumn(
      'Gebouwenclustercode',
      getPropertyName<GebouwenClusterDtoType>('code'),
      (item: GebouwenClusterDtoType) => (
        <Link key={item.gebouwClusterId} onClick={() => onGebouwenClusterClick(item)}>
          {item.code}
        </Link>
      ),
      'L'
    ),
    createCustomRenderedColumn('Gebouwenclusternaam', getPropertyName<GebouwenClusterDtoType>('naam'), undefined, 'MAX')
  ]

  const gebouwColumns = [
    createCustomRenderedColumn(
      '',
      getPropertyName<GebouwDtoType>('gebouwId'),
      (item: GebouwDtoType) => {
        const hasChildrenData = item.gebouwDelen && item.gebouwDelen?.length > 0
        return hasChildrenData ? (
          <IconButton
            iconProps={{ iconName: expandedRows[item.gebouwId] ? 'Remove' : 'Add' }}
            onClick={() => toggleRow(item.gebouwId)}
            styles={iconButtonStyles}
          />
        ) : null
      },
      30, false
    ),
    createCustomRenderedColumn(
      'Gebouwcode',
      getPropertyName<GebouwDtoType>('code'),
      (item: GebouwDtoType) => (
        <Link key={item.gebouwId} onClick={() => onGebouwenClick(item)}>
          {item.code}
        </Link>
      ),
      'L',
      false
    ),
    createCustomRenderedColumn('Gebouwnaam', getPropertyName<GebouwDtoType>('naam'), undefined, 'MAX', false)
  ]

  const gebouwdeelColumns = [
    createCustomRenderedColumn(
      'Gebouwdeelcode',
      getPropertyName<GebouwDelenDtoType>('code'),
      (item: GebouwDelenDtoType) => (
        <Link key={item.gebouwDeelId} onClick={() => onGebouwDelenClick(item)}>
          {item.code}
        </Link>
      ),
      'L',
      false
    ),
    createCustomRenderedColumn('Gebouwdeelnaam', getPropertyName<GebouwDelenDtoType>('naam'))
  ]

  const getGebouwdelenData = (item: GebouwDtoType) => item.gebouwDelen ?? []

  const onRenderDetailsHeader: IDetailsListProps['onRenderDetailsHeader'] = props => {
    const detailsHeaderStyles: Partial<IDetailsHeaderStyles> = {
      root: { padding: 0 }
    }

    return props ? <DetailsHeader {...props} styles={detailsHeaderStyles} /> : null
  }

  const onRenderChildRow: IDetailsListProps['onRenderRow'] = rowProps => {
    if (!rowProps) return null

    const { item } = rowProps
    const customStyles: Partial<IDetailsRowStyles> = {
      root: { backgroundColor: rowProps.itemIndex % 2 === 0 ? '#f7f7f7' : '#ffffff' }
    }

    return (
      <>
        <DetailsRow
          {...rowProps}
          styles={customStyles}
        />
        {expandedRows[item.gebouwId] && getGebouwdelenData && getGebouwdelenData(item).length > 0 && (
          <DetailsList
            items={getGebouwdelenData(item)}
            columns={gebouwdeelColumns}
            onItemInvoked={onGebouwDelenClick}
            setKey='child2'
            checkButtonAriaLabel='select row'
            onRenderDetailsHeader={onRenderDetailsHeader}
            compact={true}
            selectionMode={SelectionMode.none}
            styles={{ root: { paddingLeft: 100 } }}
          />
        )}
      </>
    )
  }

  const onRenderRow: IDetailsListProps['onRenderRow'] = props => {
    if (!props) return null

    const { item } = props
    const customStyles: Partial<IDetailsRowStyles> = {
      root: { backgroundColor: props.itemIndex % 2 === 0 ? '#f7f7f7' : '#ffffff' }
    }

    return (
      <>
        <DetailsRow
          {...props}
          styles={customStyles}
        />
        {expandedRows[item.gebouwClusterId] && item.gerelateerdGebouwListDto && item.gerelateerdGebouwListDto.length > 0 && (
          <div style={{ marginLeft: '20px', paddingTop: '5px', paddingBottom: '5px' }}>
            <DetailsList
              items={item.gerelateerdGebouwListDto}
              columns={gebouwColumns}
              onRenderDetailsHeader={onRenderDetailsHeader}
              onItemInvoked={onGebouwenClick}
              setKey='nested'
              checkButtonAriaLabel='select row'
              selectionMode={SelectionMode.none}
              onRenderRow={onRenderChildRow}
              compact={true}
              styles={{ root: { paddingLeft: 70 } }}
            />
          </div>
        )}
      </>
    )
  }

  return (
    <MainTemplate title='Gebouwenclusters' subTitle='Overzicht' commandItems={commandItems}>
      <GebouwenClusterEditPanel fetchData={fetchGebouwenClusters} isOpen={inEditMode} dismissPanel={dismissEditPanel} />
      <VgtSearch onSearch={setFilter} filter={validatedSearchParams.filter} />
      <DeleteWarningDialog onCancel={() => setHideDialog(true)} hideDialog={hideDialog} />
      <ErrorMessageBar error={error} />
      <div className={classNames.mainContainerStyles}>
        <ShimmeredDetailsList
          items={gebouwenClusters?.items || []}
          columns={setSortingOnColumns(columns)}
          selectionMode={SelectionMode.multiple}
          selection={selection}
          compact={true}
          onRenderRow={onRenderRow}
          onRenderDetailsHeader={onRenderDetailsHeader}
          getKey={(item: GebouwenClusterDtoType) => item?.gebouwClusterId.toString()}
          onItemInvoked={onGebouwenClusterClick}
          enableShimmer={isFetchingGebouwenClusters}
          shimmerLines={validatedSearchParams.pageSize}
          onColumnHeaderClick={(e, column) => {
            if (setOrder && column?.fieldName) {
              const key = column.fieldName
              const direction = column.isSortedDescending ? 'asc' : 'desc'
              setOrder(key, direction)
            }
          }}
        />
        <div style={{ display: 'flex', justifyContent: 'center', padding: 12 }}>
          <DefaultButton
            iconProps={{ iconName: 'previous' }}
            disabled={!gebouwenClusters?.hasPreviousPage}
            onClick={() => setPage(validatedSearchParams.page - 1)}
          />
          <Text style={{ paddingLeft: 12, paddingTop: 4, paddingRight: 12 }}>
            {gebouwenClusters?.totalCount} resultaten.
            Pagina {validatedSearchParams.page} van {gebouwenClusters?.totalPages}
          </Text>
          <DefaultButton
            iconProps={{ iconName: 'next' }}
            disabled={!gebouwenClusters?.hasNextPage}
            onClick={() => setPage(validatedSearchParams.page + 1)}
            style={{ paddingLeft: 12 }}
          />
        </div>
      </div>
    </MainTemplate>
  )
}

export default GebouwenClustersList
