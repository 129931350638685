import React from 'react'
import { DetailsList, IColumn, Link, ScrollablePane, ScrollbarVisibility } from '@fluentui/react'
import { GebouwListDto, zodiosHooks } from '../../../../api/ApiClient'
import { getPropertyName } from '../../../../lib/interfaceUtils'
import { PageIdDefinition, PAGES } from '../../../../pages'
import { z } from 'zod'
import { useNavigate } from 'react-router-dom'


type IGebouwenclusterGebouwen = z.infer<typeof GebouwListDto>;

interface IGerelateerdGebouwenListProps {
  gebouwClusterId: number
}

export const GerelateerdGebouwenList: React.FC<IGerelateerdGebouwenListProps> = ({ gebouwClusterId }) => {

  const navigate = useNavigate()

  const { data: gebouwen } = zodiosHooks.useGetGebouwenbyClusterIdClusterId(
    { params: { clusterId: gebouwClusterId } },
    { enabled: gebouwClusterId !== undefined }
  )

  const handleItemInvoked = (id: string) => {
    let url: string
    if (id) url = PAGES[PageIdDefinition.beheer.fysiek.gebouwEdit].route.replace(':id', id)
    else url = PAGES[PageIdDefinition.beheer.fysiek.gebouwEdit].route
    navigate(url)
  }

  const columns: IColumn[] = [
    {
      key: getPropertyName<IGebouwenclusterGebouwen>('code'),
      name: 'Code',
      fieldName: getPropertyName<IGebouwenclusterGebouwen>('code'),
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true,
      data: 'string',
      onRender: (item: IGebouwenclusterGebouwen) => (
        <Link key={item.id} onClick={() => handleItemInvoked(item.id.toString())}>
          {item.code}
        </Link>
      )
    },
    {
      key: getPropertyName<IGebouwenclusterGebouwen>('naam'),
      name: 'Naam',
      fieldName: getPropertyName<IGebouwenclusterGebouwen>('naam'),
      minWidth: 300,
      maxWidth: 350,
      isResizable: true,
      isRowHeader: true,
      data: 'string'
    },
    {
      key: getPropertyName<IGebouwenclusterGebouwen>('gebouwClusterNaam'),
      name: 'Gebouwencluster',
      fieldName: getPropertyName<IGebouwenclusterGebouwen>('gebouwClusterNaam'),
      minWidth: 300,
      maxWidth: 350,
      isResizable: true,
      isRowHeader: true,
      data: 'string'
    },
    {
      key: getPropertyName<IGebouwenclusterGebouwen>('aantalAdresseerbaar'),
      name: 'Aantal adresseerbaar',
      fieldName: getPropertyName<IGebouwenclusterGebouwen>('aantalAdresseerbaar'),
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true,
      data: 'string',
    },
    {
      key: getPropertyName<IGebouwenclusterGebouwen>('aantalOverigGebouwd'),
      name: 'Aantal overig gebouwd',
      fieldName: getPropertyName<IGebouwenclusterGebouwen>('aantalOverigGebouwd'),
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true,
      data: 'string',
    },
    {
      key: getPropertyName<IGebouwenclusterGebouwen>('aantalTerreinen'),
      name: 'Aantal terreinen',
      fieldName: getPropertyName<IGebouwenclusterGebouwen>('aantalTerreinen'),
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true,
      data: 'string'
    }
  ]
  return (
      <div style={{ height: '78.7vh', position: 'relative' }}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <DetailsList
            columns={columns}
            styles={{ root: { fontSize: '14px' } }}
            items={gebouwen?.items ?? []}
          />
        </ScrollablePane>
      </div>
  )
}